<template>
  <div class="mt-2 bg-white p-6 rounded-lg">
    <h2 class="text-2xl font-bold text-sky-700 mb-6">Vizualizace investice</h2>

    <div class="flex flex-col flex-wrap gap-8 items-center mb-8 sticky top-14 bg-white pt-2 pb-3 pl-1 pr-1 rounded">
      <div class="w-full md:w-full">
        <label for="saleYear" class="block mb-2 text-gray-700">Doba držení nemovitosti:</label>
        <input
            id="saleYear"
            type="range"
            :min="1"
            :max="35"
            v-model="saleYear"
            @change="updateGraph"
            class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer text-sky-600"
        />
        <p class="text-gray-600 mt-2">
          <span class="font-semibold">{{ saleYear }} let</span>
        </p>
      </div>
    </div>

    <div :class="{'flex w-full mb-16 gap-4': !isCompare, 'flex flex-col mb-16 gap-4': isCompare}">
      <div :class="{'w-3/12': !isCompare, 'w-full': isCompare}">
        <div class="w-full mb-3">
          <h3 class="text-md mb-1">Komulativní CashFlow</h3>
          <h2 class="text-3xl font-bold text-sky-700">{{ formatCurrency(totalProfit) }}</h2>
        </div>
        <div class="w-full mb-3">
          <h3 class="text-md mb-1">Hodnota nemovitosti</h3>
          <h2 class="text-3xl font-bold text-sky-700">{{ formatCurrency(propertyValueAtSale) }}</h2>
        </div>
        <div class="w-full">
          <h3 class="text-sm mb-1">Hodnota po inflaci</h3>
          <h2 class="text-2xl font-bold text-sky-700">{{ formatCurrency(propertyValueWithoutInflation) }}</h2>
        </div>
      </div>
      <div :class="{'w-9/12 flex items-center': !isCompare, 'w-full': isCompare}">
        <div class="w-full grid grid-cols-1 md:grid-cols-3 gap-3 content-center">
          <div class="flex-1 pl-4 pr-4 pt-7 pb-7 rounded-lg shadow-md bg-gray-50 text-gray-950 text-center justify-center items-center flex flex-col">
            <h3 class="text-xs font-medium mb-2">Celkové investované prostředky (včetně provozních doplatků)</h3>
            <p class="text-2xl font-semibold">{{ formatCurrency(totalInvestedFunds) }}</p>
          </div>

          <div class="flex-1 p-4 rounded-lg shadow-md bg-gray-50 text-gray-950 text-center justify-center items-center flex flex-col">
            <h3 class="text-xs font-medium mb-2">⌀ roční zhodnocení</h3>
            <p class="text-2xl font-semibold">{{ averageAnnualReturn }}%</p>
          </div>

          <div class="flex-1 p-4 rounded-lg shadow-md bg-gray-50 text-gray-950 text-center justify-center items-center flex flex-col">
            <h3 class="text-xs font-medium mb-2">Celkové zhodnocení</h3>
            <p class="text-2xl font-semibold">{{ roi }}%</p>
          </div>
        </div>
      </div>
    </div>

    <div :class="{'w-full flex': !isCompare, 'w-full flex flex-col': isCompare}">
      <div :class="{'w-1/3 grid grid-cols-1 gap-4 mr-3': !isCompare, 'w-full grid gap-5': isCompare}">
        <div class="flex-1 p-4 rounded-lg shadow-md bg-gray-50 text-gray-950 text-center justify-center items-center flex flex-col">
          <h3 class="text-xs font-medium mb-2">Celkový čistý zisk z investice včetně prodeje (před zdaněním)</h3>
          <p class="text-2xl font-semibold">{{ formatCurrency(getSalesProfit) }}</p>
        </div>
        <div class="flex-1 p-4 rounded-lg shadow-md bg-gray-50 text-gray-950 text-center justify-center items-center flex flex-col">
          <h3 class="text-xs font-medium mb-2">Celkové příjmy z nemovitosti po zdanění</h3>
          <p class="text-2xl font-semibold">{{ formatCurrency(netIncomeForYear) }}</p>
        </div>
        <div class="flex-1 p-4 rounded-lg shadow-md bg-gray-50 text-gray-950 text-center justify-center items-center flex flex-col">
          <h3 class="text-xs font-medium mb-2">Umořeno z hypotéky</h3>
          <p class="text-2xl font-semibold">{{ formatCurrency(totalPrincipalPaid) }}</p>
        </div>
        <div class="flex-1 p-4 rounded-lg shadow-md bg-gray-50 text-gray-950 text-center justify-center items-center flex flex-col">
          <h3 class="text-xs font-medium mb-2">Zaplacené úroky</h3>
          <p class="text-2xl font-semibold">{{ formatCurrency(totalInterestPaid) }}</p>
        </div>
        <div class="flex-1 p-4 rounded-lg shadow-md bg-gray-50 text-gray-950 text-center justify-center items-center flex flex-col">
          <h3 class="text-xs font-medium mb-2">Zaplaceno celkem na splátkách</h3>
          <p class="text-2xl font-semibold">{{ formatCurrency(totalLoanPayments) }}</p>
        </div>
      </div>
      <div :class="{'w-2/3': !isCompare, 'w-full mt-4': isCompare}">
        <h2 class="text-center font-semibold">Model očekávaného růstu ceny nemovitosti</h2>
        <ChartExpectedDevelop v-if="showGraph" :chartData="chartData" :chartOptions="chartOptions" />
        <div v-else class="w-full h-full" />
        <div class="bg-gray-100 rounded-lg p-4 flex flex-col space-y-2">
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 bg-green-600 rounded"></div>
            <span class="text-gray-600 text-sm">Optimističtější scénář (+2%)</span>
          </div>
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 bg-blue-600 rounded"></div>
            <span class="text-gray-700 text-sm">Vámi nastavený scénář</span>
          </div>
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 bg-red-600 rounded"></div>
            <span class="text-gray-700 text-sm">Pesimističtější scénář (-2%)</span>
          </div>
        </div>
      </div>
    </div>

    <div :class="{'w-full flex gap-3': !isCompare, 'w-full flex flex-col gap-4 mt-4': isCompare}">
      <div :class="{'w-1/2 mt-12': !isCompare, 'w-full': isCompare}">
        <h2 class="text-center font-semibold">Růst příjmu z nemovitosti vs splátky financování</h2>
        <ChartExpectedDevelop v-if="showGraph" :chartData="chartDataIncomeVsLoanPayments" :chartOptions="chartOptions" />
        <div v-else class="w-full h-full" />
        <div class="bg-gray-100 rounded-lg p-4 flex flex-col space-y-2">
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 bg-green-600 rounded"></div>
            <span class="text-gray-700 text-sm">Příjem z nemovitosti</span>
          </div>
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 bg-blue-600 rounded"></div>
            <span class="text-gray-700 text-sm">Splátky úvěrů</span>
          </div>
        </div>
      </div>

      <div :class="{'w-1/2 mt-12': !isCompare, 'w-full': isCompare}">
        <h2 class="text-center font-semibold">Růst příjmů z nemovitosti vs náklady na nemovitost</h2>
        <ChartExpectedDevelop v-if="showGraph" :chartData="chartDataIncomeVsCosts" :chartOptions="chartOptions" />
        <div v-else class="w-full h-full" />
        <div class="bg-gray-100 rounded-lg p-4 flex flex-col space-y-2">
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 bg-green-600 rounded"></div>
            <span class="text-gray-700 text-sm">Příjem z nemovitost</span>
          </div>
          <div class="flex items-center space-x-2">
            <div class="w-4 h-4 bg-red-600 rounded"></div>
            <span class="text-gray-700 text-sm">Náklady na nemovitost</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import ChartExpectedDevelop from "@/components/graphs/ChartExpectedDevelop.vue";
import { InvestmentService } from "@/functions/investmentService";

export default {
  name: "ReturnAnalysis",
  components: { ChartExpectedDevelop },

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    isCompare: {
      type:Boolean,
      default: () => false,
    }
  },

  data() {
    return {
      saleYear: 30,
      showGraph: true,
    };
  },

  computed: {
    data() {
      return this.modelValue;
    },
    initialInvestment() {
      return InvestmentService.calculateInitialInvestment(this.data);
    },
    totalInvestedFunds() {
      return InvestmentService.calculateTotalInvestedCash(this.data, this.saleYear);
    },
    totalIncome() {
      return InvestmentService.calculateTotalIncome(this.data, this.saleYear);
    },
    operatingExpenses() {
      return InvestmentService.calculateOperatingExpenses(this.data, this.saleYear);
    },
    totalLoanPayments() {
      return InvestmentService.calculateLoanPayments(this.data, this.saleYear);
    },
    totalInterestPaid() {
      return InvestmentService.calculateTotalInterestPayments(this.data, this.saleYear);
    },
    totalPrincipalPaid() {
      return InvestmentService.calculateTotalPrincipalPaid(this.data, this.saleYear);
    },

    getSalesProfit(){
      return InvestmentService.calculateTotalProfit(this.data, this.saleYear);
    },


    totalProfit() {
      return InvestmentService.calculateCashFlowForYear(this.data, this.saleYear);
    },
    roi() {
      return InvestmentService.calculateROI(this.data, this.saleYear);
    },

    averageAnnualReturn() {
      const years = this.saleYear;
      const roi = InvestmentService.calculateROI(this.data, years); // ROI za celé období

      // Výpočet CAGR (Compound Annual Growth Rate)
      const cagr = Math.pow(1 + (roi / 100), 1 / years) - 1;

      return (cagr * 100).toFixed(2); // Vrátí CAGR v procentech, zaokrouhleno na dvě desetinná místa
    },


    propertyValueAtSale() {
      const years = this.saleYear;

      return InvestmentService.calculatePropertyValueForYear(this.data, years);
    },

    propertyValueWithoutInflation() {
      const inflationRate = 0.025;
      const inflationAdjustment = Math.pow(1 + inflationRate, this.saleYear);
      return this.propertyValueAtSale / inflationAdjustment;
    },

    netIncomeForYear() {
      return InvestmentService.calculateCashFlowAfterTax(this.data, this.saleYear);
    },

    chartData() {
      const years = Array.from({ length: this.saleYear }, (_, i) => i + 1);
      const labels = years.map((year) => `${year}. rok`);

      const propertyValuesOptimistic = years.map((year) => {
        const appreciationRate = (this.data.property_appreciation + 2) / 100;
        return this.data.after_repair_value * Math.pow(1 + appreciationRate, year);
      });

      const propertyValuesNeutral = years.map((year) => {
        const appreciationRate = this.data.property_appreciation / 100;
        return this.data.after_repair_value * Math.pow(1 + appreciationRate, year);
      });

      const propertyValuesPessimistic = years.map((year) => {
        const appreciationRate = (this.data.property_appreciation - 2) / 100;
        return this.data.after_repair_value * Math.pow(1 + appreciationRate, year);
      });
      return {
        labels: labels,
        datasets: [
          {
            label: 'Optimističtější scénář (+2%)',
            data: propertyValuesOptimistic,
            borderColor: 'rgba(72, 178, 72, 1)',
            backgroundColor: 'rgba(72, 178, 72, 0.2)',
            fill: true,
          },
          {
            label: 'Vámi nastavený scénář',
            data: propertyValuesNeutral,
            borderColor: 'rgba(0, 120, 255, 1)',
            backgroundColor: 'rgba(0, 120, 255, 0.2)',
            fill: true,
          },
          {
            label: 'Pesimističtější scénář (-2%)',
            data: propertyValuesPessimistic,
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            fill: true,
          },
        ],
      };
    },
    chartDataIncomeVsLoanPayments() {
      const years = Array.from({ length: this.saleYear }, (_, i) => i + 1);
      const labels = years.map((year) => `${year}. rok`);

      const incomeData = years.map((year) => {
        return InvestmentService.calculateIncomeForYear(this.data, year);
      });

      const loanPaymentsData = years.map((year) => {
        return InvestmentService.calculateLoanPaymentsForYear(this.data, year);
      });

      return {
        labels: labels,
        datasets: [
          {
            label: 'Příjem z nemovitosti',
            data: incomeData,
            borderColor: 'rgba(72, 178, 72, 1)',
            backgroundColor: 'rgba(72, 178, 72, 0.2)',
            fill: true,
          },
          {
            label: 'Splátky úvěrů',
            data: loanPaymentsData,
            borderColor: 'rgba(0, 120, 255, 1)',
            backgroundColor: 'rgba(0, 120, 255, 0.2)',
            fill: true,
          },
        ],
      };
    },

    chartDataIncomeVsCosts() {
      const years = Array.from({ length: this.saleYear }, (_, i) => i + 1);
      const labels = years.map((year) => `${year}. rok`);

      const incomeData = years.map((year) => {
        return InvestmentService.calculateIncomeForYear(this.data, year);
      });

      const totalCostsData = years.map((year) => {
        // Spočítáme provozní náklady pro daný rok
        const operatingExpenses = InvestmentService.calculateOperatingExpensesForYear(this.data, year);
        // Spočítáme splátky úvěru pro daný rok
        const loanPayments = InvestmentService.calculateLoanPaymentsForYear(this.data, year);
        // Celkové náklady jsou součtem provozních nákladů a splátek úvěru
        return operatingExpenses + loanPayments;
      });

      return {
        labels: labels,
        datasets: [
          {
            label: 'Příjem z nemovitosti',
            data: incomeData,
            borderColor: 'rgba(72, 178, 72, 1)',
            backgroundColor: 'rgba(72, 178, 72, 0.2)',
            fill: true,
          },
          {
            label: 'Celkové náklady',
            data: totalCostsData,
            borderColor: 'rgba(255, 99, 132, 1)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            fill: true,
          },
        ],
      };
    },

    chartOptions() {
      return {
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      };
    },
  },

  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: "CZK",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value);
    },
    updateGraph(){
      this.showGraph = false;

      setTimeout(()=>{
        this.showGraph = true;
      },100)
    }
  },
};
</script>

<style scoped>
/* Vaše styly */
</style>
