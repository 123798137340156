<template>
  <div class="p-4 rounded-lg">
    <h2 class="text-2xl font-semibold mb-4">Kritéria pro nemovitosti</h2>

    <p class="xl mt-4 mb-9">
      V této části můžete nastavit kritéria pro nemovitosti do kterých chcete investovat.
      U jednotlivých nemovitostí následně uvidíte, jaká kritéria splňují a můžete mezi
      nimi snadno filtrovat. To Vám může pomoci rychleji zhodnotit, zda daná nemovitost
      spadá do Vaší investiční strategie.
    </p>

    <div v-if="limits.length === 0">Načítání limitů...</div>

    <div v-for="(limit, index) in limits" :key="index" class="mb-6">
      <div class="flex items-center justify-between">
        <div>
          <h3 class="font-semibold text-lg">{{ limit.name }}</h3>
          <p class="text-sm text-gray-600">{{ limit.description }}</p>
        </div>

        <div class="flex items-center">
          <input
              type="checkbox"
              :id="'switch' + index"
              class="toggle-checkbox"
              v-model="limit.is_active"
          />
          <label
              :for="'switch' + index"
              class="toggle-label block bg-blue-600 h-6 w-11 rounded-full"
          ></label>
        </div>
      </div>

      <div v-if="limit.is_active" class="mt-3">
        <label v-if="index < 2" class="block text-gray-700 mb-2">Zadejte v Kč:</label>
        <label v-else class="block text-gray-700 mb-2">Zadejte v procentech:</label>

        <div class="relative">
          <input
              type="number"
              v-model="limit.value"
              class="block w-full p-2 pr-10 border rounded-md text-gray-700"
          />
          <span v-if="index < 2" class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
          <span v-else class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
        </div>
      </div>
    </div>

    <div class="w-full justify-end flex mt-10">
      <button
          class="bg-sky-500 text-white py-2 px-8 rounded hover:bg-sky-700"
          @click="saveLimits"
          :disabled="loading"
      >
        Uložit
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "LimitsSetting",
  data() {
    return {
      limits: [],
      loading: false
    };
  },
  methods: {
    fetchLimits() {
      this.loading = true;
      this.$axios
          .get("/criteria")
          .then((response) => {
            const data = response.data;
            this.limits = [
              {
                name: "Kritérium maximální potřebné hotovosti",
                description: "Jakou maximální hotovost chcete investovat z vlastních zdrojů do jedné nemovitosti?",
                is_active: Boolean(data.is_cash_allow),
                value: data.cash_value
              },
              {
                name: "Limit pro cashflow",
                description: "Jaké minimální cashflow očekáváte od investice? Můžete zadat i záporné hodnoty.",
                is_active: Boolean(data.is_cashflow_allow),
                value: data.cashflow_value
              },
              {
                name: "Limit pro ROI",
                description: "Minimální požadovaný ROI (Return on Investment).",
                is_active: Boolean(data.is_roi_allow),
                value: data.roi_value
              },
              {
                name: "Limit pro výnos",
                description: "Minimální požadovaný výnos z investice.",
                is_active: Boolean(data.is_yield_allow),
                value: data.yield_value
              }
            ];
            this.loading = false;
          })
          .catch((error) => {
            console.error("Chyba při načítání limitů:", error);
            this.loading = false;
          });
    },
    saveLimits() {
      const requestData = {
        is_cash_allow: this.limits[0].is_active,
        cash_value: this.limits[0].value,
        is_cashflow_allow: this.limits[1].is_active,
        cashflow_value: this.limits[1].value,
        is_roi_allow: this.limits[2].is_active,
        roi_value: this.limits[2].value,
        is_yield_allow: this.limits[3].is_active,
        yield_value: this.limits[3].value
      };

      this.$axios
          .put("/criteria", requestData)
          .then(() => {
            this.$flashMessage.success("Nastavení bylo úspěšně uloženo");
            this.fetchLimits();
          })
          .catch(() => {
            this.$flashMessage.success("Při ukládání nastala chyba");
          });
    }
  },
  mounted() {
    this.fetchLimits();
  }
};
</script>

<style scoped>
/* Přepínač */
.toggle-checkbox:checked {
  right: 0;
}

.toggle-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label {
  position: relative;
  cursor: pointer;
  display: inline-block;
  width: 2.75rem;
  height: 1.5rem;
  background-color: #c6c6c6;
  border-radius: 9999px;
  transition: background-color 0.2s;
}

.toggle-label:before {
  content: "";
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: 1rem;
  height: 1rem;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s;
}

.toggle-checkbox:checked + .toggle-label {
  background-color: #0ea5e9;
}

.toggle-checkbox:checked + .toggle-label:before {
  transform: translateX(1.25rem);
}
</style>
