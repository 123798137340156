<template>
  <div class="web-modal-overlay" v-if="shouldShowModal">
    <div class="bg-white text-black py-12 px-12 rounded-lg w-full max-w-6xl mx-auto">
      <div class="flex flex-col md:flex-row items-center justify-center gap-12">

        <!-- Payment Plan Box (on mobile first) -->
        <div class="w-full md:w-1/2 bg-sky-100 p-8 rounded-lg shadow-md order-1 md:order-2">
          <!-- Payment switch -->
          <div class="flex justify-center mb-6">
            <button :class="isMonthly ? 'bg-sky-500 text-white' : 'bg-sky-200 text-sky-700'" class="px-6 py-2 mx-2 rounded-lg" @click="isMonthly = true">Měsíční</button>
            <button :class="!isMonthly ? 'bg-sky-500 text-white' : 'bg-sky-200 text-sky-700'" class="px-6 py-2 mx-2 rounded-lg" @click="isMonthly = false">Roční</button>
          </div>

          <h3 class="text-xl font-bold mb-4">14 DNÍ ZDARMA, PAK</h3>

          <p class="text-6xl font-bold mb-4">{{ isMonthly ? '295 Kč' : '2950 Kč' }}</p>
          <p class="text-gray-600 mb-6">{{ isMonthly ? 'měsíčně' : 'ročně' }}</p>

          <a @click.prevent="startSubscription" class="cursor-pointer bg-sky-500 w-full text-white px-6 py-3 rounded-lg block text-center hover:bg-sky-700"
          >Vyzkoušet na 14 dní zdarma
          </a>

          <ul class="text-left mt-6 space-y-2">
            <li class="flex items-center">
              <svg class="h-5 w-5 text-green-500" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
              <span class="ml-2 text-gray-600">Jedno předplatné, neomezený přístup!</span>
            </li>
          </ul>
        </div>

        <!-- Features (below on mobile) -->
        <div class="w-full md:w-1/2 text-left order-2 md:order-1">
          <div class="space-y-6">
            <div class="flex items-center">
              <div class="bg-sky-100 p-3 rounded-lg flex items-center justify-center w-12 h-12">
                <font-awesome-icon icon="infinity" class="text-xl" />
              </div>
              <div class="ml-4">
                <h3 class="text-lg font-bold">Neomezený počet nemovitostí k analýze</h3>
                <p class="text-gray-600">Analyzujte své investice v Rilo bez jakéhokoliv omezení</p>
              </div>
            </div>

            <div class="flex items-center">
              <div class="bg-sky-100 p-3 rounded-lg flex items-center justify-center w-12 h-12">
                <font-awesome-icon icon="stopwatch-20" class="text-xl" />
              </div>
              <div class="ml-4">
                <h3 class="text-lg font-bold">Vizualizace investic v čase</h3>
                <p class="text-gray-600">Jednoduše vizualizujte své investice v čase a kontrolujte všechny parametry</p>
              </div>
            </div>

            <div class="flex items-center">
              <div class="bg-sky-100 p-3 rounded-lg flex items-center justify-center w-12 h-12">
                <font-awesome-icon icon="not-equal" class="text-xl" />
              </div>
              <div class="ml-4">
                <h3 class="text-lg font-bold">Vlastní kritéria investic</h3>
                <p class="text-gray-600">Nastavte si pro své investice kritéria a Rilo je vyhodnotí za Vás.</p>
              </div>
            </div>

            <div class="flex items-center">
              <div class="bg-sky-100 p-3 rounded-lg flex items-center justify-center w-12 h-12">
                <font-awesome-icon icon="window-maximize" class="text-xl" />
              </div>
              <div class="ml-4">
                <h3 class="text-lg font-bold">Přístup k Rilo Pluginu</h3>
                <p class="text-gray-600">Aktivujte si Rilo Plugin ve svém prohlížeči a analyzujte nemovitosti ještě rychleji</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/pinia/userStore";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  name: "ModalImportant",
  components: {FontAwesomeIcon},
  data() {
    return {
      isMonthly: true,
      SubscriptionPaid: false,
      SubscriptionNotPaid: false,
      shouldShowModal: false,
      CallForSubscriptionActivation: false,
    };
  },

  mounted() {
    this.checkSubscriptionStatus();
  },

  computed:{
    subscriptionType(){
      if(this.isMonthly){
        return "monthly"
      }else{
        return "yearly"
      }
    }
  },

  methods:{
    async startSubscription() {
      try {
        const response = await this.$axios.post('/create-checkout-session', {
          subscription_type: this.subscriptionType
        });

        window.location.href = response.data.checkoutUrl;
      } catch (error) {
        console.error('Chyba při vytváření předplatného:', error);
      }
    },

    async checkSubscriptionStatus() {
      const urlParams = new URLSearchParams(window.location.search);
      const subscriptionPaid = urlParams.has("subscriptionPaid");
      const subscriptionError = urlParams.has("subscriptionError");
      const store = useUserStore();
      console.log(store);
      const userHasActiveSubscription = store.user.active_subscription;
      if (subscriptionPaid) {
        this.SubscriptionPaid = true;
        this.shouldShowModal = true;
      } else if (subscriptionError) {
        this.SubscriptionNotPaid = true;
        this.shouldShowModal = true;
      } else if (!userHasActiveSubscription) {
        this.CallForSubscriptionActivation = true;
        this.shouldShowModal = true;
      }
    },
  },
};
</script>

<style scoped>
.web-modal-overlay {
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.43);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Allow scrolling */

  @media screen and (max-width: 800px){
    position: absolute;
  }
}

.bg-white {
  width: 100%; /* Ensure content fills the width */
}
</style>
