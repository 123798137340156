<template>
  <div class="min-h-full pt-12">
    <h2 class="text-3xl font-extrabold mb-6 text-gray-800">
      Nastavení aplikace
    </h2>
    <div class="flex flex-col sm:flex-row">
      <!-- Sidebar -->
      <aside id="default-sidebar" class="w-full sm:w-1/5 min-w-64 h-full transition-transform sm:translate-x-0" aria-label="Sidebar">
        <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50 rounded-lg">
          <ul class="flex flex-row sm:flex-col space-y-2 space-x-2 sm:space-x-0 font-medium overflow-x-auto">
            <li class="w-[45%] sm:w-full">
              <router-link :to="{ name: 'ProfileSetting' }" class="flex items-center p-4 text-gray-900 rounded-lg hover:bg-gray-100 group" active-class="bg-gray-200">
                <font-awesome-icon icon="user" />
                <span class="ms-3">Nastavení profilu</span>
              </router-link>
            </li>
            <li class="w-[45%] sm:w-full">
              <router-link :to="{ name: 'CreditsSetting' }" class="flex items-center p-4 text-gray-900 rounded-lg hover:bg-gray-100 group" active-class="bg-gray-200">
                <font-awesome-icon icon="coins" />
                <span class="ms-3">Kredity</span>
              </router-link>
            </li>
            <li class="w-[45%] sm:w-full">
              <router-link :to="{ name: 'LimitsSetting' }" class="flex items-center p-4 text-gray-900 rounded-lg hover:bg-gray-100 group" active-class="bg-gray-200">
                <font-awesome-icon icon="greater-than-equal" />
                <span class="ms-3">Kritéria nemovitostí</span>
              </router-link>
            </li>
            <li class="w-[45%] sm:w-full">
              <router-link :to="{ name: 'TemplateSetting' }" class="flex items-center p-4 text-gray-900 rounded-lg hover:bg-gray-100 group"  :class="{ 'bg-gray-200': isActiveTemplate }">
                <font-awesome-icon icon="file-lines" />
                <span class="ms-3">Šablony nemovitostí</span>
              </router-link>
            </li>
            <li class="w-[45%] sm:w-full">
              <router-link :to="{ name: 'SubscriptionSetting' }" class="flex items-center p-4 text-gray-900 rounded-lg hover:bg-gray-100 group" active-class="bg-gray-200">
                <font-awesome-icon icon="wallet" />
                <span class="ms-3">Předplatné & faktury</span>
              </router-link>
            </li>
          </ul>
        </div>
      </aside>

      <!-- Content -->
      <div class="p-4 bg-white w-full sm:w-4/5 ml-3 rounded-lg">
        <!-- Zde se bude zobrazovat obsah podle zvolené cesty -->
        <router-view v-slot="{ Component, route }">
          <transition :name="route.meta.transition || 'fade'" mode="out-in">
            <component v-if="Component" :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'SettingPage.vue',
  components: {
    FontAwesomeIcon,
  },

  computed:{
    isActiveTemplate() {
      return this.$route.name === 'TemplateSetting' ||
          this.$route.name === 'AddTemplateSetting' ||
          this.$route.name === 'EditTemplateSetting';
    }
  }
};
</script>

<style scoped>
/* Animace pro přechod z PlannProperties do AddProperties nebo EditProperties */
.slide-left-enter-active {
  animation: slideInFromRight 0.3s ease-out forwards;
}
.slide-left-leave-active {
  animation: slideOutToLeft 0.3s ease-in forwards;
}

/* Animace pro přechod z AddProperties nebo EditProperties do PlannProperties */
.slide-right-enter-active {
  animation: slideInFromLeft 0.3s ease-out forwards;
}
.slide-right-leave-active {
  animation: slideOutToRight 0.3s ease-in forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%);
    opacity: 0;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(50%);
    opacity: 0;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.active{
  background: red;
}

</style>
