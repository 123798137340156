<template>
  <div @click="goToEditPage"
       class="cursor-pointer relative bg-white rounded-lg shadow-lg hover:shadow-xl mt-4 p-4 flex items-center justify-between">
    <!-- Template Info -->
    <div class="flex items-center">
      <div class="flex items-center">
        <font-awesome-icon icon="file-lines" class="mr-4" />

        <h3 class="text-lg font-semibold text-gray-800">
          {{ template.name }}
        </h3>
      </div>
    </div>
    <!-- Dropdown Menu for Actions -->
    <div class="relative">
      <button @click.stop="toggleDropdown" ref="button" class="rounded-full w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 text-gray-600">
        <font-awesome-icon icon="ellipsis-v" />
      </button>
      <!-- Dropdown content -->
      <div v-if="dropdownOpen" ref="dropdown" class="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
        <ul class="py-1">
          <li>
            <button @click.stop="goToEditPage" class="flex items-center px-4 py-2 w-full text-sm text-gray-700 hover:bg-gray-100">
              <font-awesome-icon icon="edit" class="mr-2"/> Upravit
            </button>
          </li>
          <li>
            <button @click.stop="confirmDelete" class="flex items-center px-4 py-2 w-full text-sm text-red-600 hover:bg-gray-100">
              <font-awesome-icon icon="trash" class="mr-2"/> Smazat
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!-- Confirm Delete Modal -->
    <DeleteConfirm
        :is-visible="isModalDeleteOpen"
        @close="closeModalDeleteTemplate"
        @confirm="deleteTemplate"
        message="Opravdu si přejete smazat tuto šablonu?"
    />
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import DeleteConfirm from '@/components/share/DeleteConfirm.vue';

export default {
  name: 'SingleTemplate',
  components: {
    FontAwesomeIcon,
    DeleteConfirm,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropdownOpen: false,
      isModalDeleteOpen: false,
    };
  },
  methods: {
    goToEditPage() {
      this.$router.push({ name: 'EditTemplateSetting', params: { id: this.template.id } });
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDropdown(event) {
      if (this.dropdownOpen && !this.$refs.dropdown.contains(event.target) && !this.$refs.button.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
    confirmDelete() {
      this.isModalDeleteOpen = true;
      this.dropdownOpen = false;
    },
    closeModalDeleteTemplate() {
      this.isModalDeleteOpen = false;
    },
    deleteTemplate() {
      // Zde zavolej API pro smazání šablony
      this.$axios.delete(`/template-properties/${this.template.id}`)
          .then(() => {
            this.$emit('deleted', this.template.id);
            this.isModalDeleteOpen = false;
          })
          .catch(error => {
            console.error('Error deleting template:', error);
          });
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdown);
  },
};
</script>

<style scoped>
/* Přidej potřebné styly, pokud je to nutné */
</style>
