<template>
  <section class="bg-sky-500 md:bg-sky-700 h-screen flex">
    <div class="hidden md:flex md:w-1/2 justify-center items-center bg-sky-600">
      <div class="flex flex-col items-center">
        <img src="/images/big-single-logo-white.svg" alt="Valuo Logo" class="mb-4 w-36" />
        <p class="text-white text-4xl font-bold mb-2">Analýza nemovitostí</p>
        <p class="text-white text-lg font-light">Na jedno kliknutí!</p>
      </div>
    </div>

    <div class="w-full md:w-1/2 flex justify-center items-center bg-white">
      <div class="w-full max-w-md p-8 space-y-6">
        <h1 class="text-2xl font-bold text-gray-900 text-center">Obnovit heslo</h1>

        <p v-if="errorMessage" class="text-red-500 text-center">{{ errorMessage }}</p>
        <p v-if="successMessage" class="text-green-500 text-center">{{ successMessage }}</p>

        <form @submit.prevent="sendResetLink" class="space-y-6">
          <div>
            <label for="email" class="block mb-2 text-sm font-medium text-gray-900">E-mail</label>
            <input
                v-model="email"
                type="email"
                id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="email@email.cz"
                required
            />
          </div>
          <button :disabled="loading" type="submit" class="w-full text-white bg-sky-500 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex justify-center items-center">
            <span v-if="loading" class="mr-2">
              <font-awesome-icon icon="spinner" spin />
            </span>
            Odeslat resetovací odkaz
          </button>
          <p class="text-sm font-light text-gray-500">
            Vzpomněli jste si?
            <router-link to="/login" class="font-medium text-primary-600 hover:underline">
              Přihlaste se zde
            </router-link>
          </p>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: '',
      errorMessage: '',
      successMessage: '',
      loading: false,
    };
  },
  methods: {
    sendResetLink() {
      this.loading = true;
      this.errorMessage = '';
      this.successMessage = '';

      axios.post(`${process.env.VUE_APP_API_ADDRESS}api/password/email`, { email: this.email })
          .then(response => {
            this.successMessage = 'Odkaz pro obnovení hesla byl odeslán na váš e-mail.';
            console.log(response);
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              this.errorMessage = 'Tento e-mail nebyl nalezen.';
            } else {
              this.errorMessage = 'Došlo k chybě při odesílání e-mailu.';
            }
          })
          .finally(() => {
            this.loading = false;
          });
    }
  },
};
</script>
