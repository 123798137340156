// investmentService.js

export const InvestmentService = {
    /**
     * Metoda pro výpočet počáteční investice.
     *
     * @param {Object} property
     * @returns {number}
     */
    calculateInitialInvestment(property) {
        const purchasePrice = property.purchase_price;
        const otherInitialCosts = property.other_initial_costs;
        const repairCosts = property.repair_costs.reduce(
            (sum, cost) => sum + cost.amount,
            0
        );

        let totalFinancedAmount = 0;

        // Pokud je využito financování
        if (
            property.is_multiple_financing &&
            property.financing &&
            property.financing.length > 0
        ) {
            for (const loan of property.financing) {
                const amount = loan.amount && loan.amount > 0 ? loan.amount : 0;
                const investmentPercentage =
                    loan.investment_percentage && loan.investment_percentage > 0
                        ? loan.investment_percentage
                        : 0;
                const financedAmount = amount * (investmentPercentage / 100);
                totalFinancedAmount += financedAmount;
            }
        }

        // Výpočet počáteční investice (vlastní kapitál)
        const initialInvestment =
            purchasePrice - totalFinancedAmount + otherInitialCosts + repairCosts;

        return initialInvestment;
    },

    /**
     * Výpočet celkových příjmů za dobu držení nemovitosti.
     *
     * @param {Object} property
     * @param {number} estimatedTime
     * @returns {number}
     */
    calculateTotalIncome(property, estimatedTime) {
        const grossRent = property.gross_rent;
        const vacancyRate = property.vacancy_rate / 100;
        const otherIncome = property.other_income.reduce(
            (sum, income) => sum + income.amount,
            0
        );
        const incomeGrowth = property.income_growth / 100;

        const initialRentIncome = grossRent * 12 * (1 - vacancyRate);
        const initialOtherIncome = otherIncome * 12;
        const totalIncomeFirstYear = initialRentIncome + initialOtherIncome;

        let totalIncome = 0;

        for (let year = 1; year <= estimatedTime; year++) {
            const yearlyIncome =
                totalIncomeFirstYear * Math.pow(1 + incomeGrowth, year - 1);
            totalIncome += yearlyIncome;
        }

        return totalIncome;
    },

    /**
     * Výpočet operativních nákladů za dobu držení.
     *
     * @param {Object} property
     * @param {number} holdingPeriod
     * @returns {number}
     */
    calculateOperatingExpenses(property, holdingPeriod) {
        const expenseGrowth = property.expense_growth / 100;
        const initialOperatingExpenses =
            property.operating_expenses.reduce(
                (sum, expense) => sum + expense.amount,
                0
            ) * 12;

        let totalOperatingExpenses = 0;

        for (let year = 1; year <= holdingPeriod; year++) {
            const yearlyOperatingExpenses =
                initialOperatingExpenses * Math.pow(1 + expenseGrowth, year - 1);
            totalOperatingExpenses += yearlyOperatingExpenses;
        }

        return totalOperatingExpenses;
    },

    /**
     * Výpočet celkových splátek financování.
     *
     * @param {Object} property
     * @param {number} holdingPeriod
     * @returns {number}
     */
    calculateLoanPayments(property, holdingPeriod) {
        if (
            !property.is_multiple_financing ||
            !property.financing ||
            property.financing.length === 0
        ) {
            return 0;
        }

        let totalLoanPayments = 0;

        for (const loan of property.financing) {
            const amortizationSchedule = this.generateAmortizationSchedule(loan, holdingPeriod);

            const totalPaymentsForLoan = amortizationSchedule.reduce((sum, payment) => sum + payment.totalPayment, 0);
            totalLoanPayments += totalPaymentsForLoan;
        }

        return totalLoanPayments;
    },

    generateAmortizationSchedule(loan, holdingPeriod) {
        const schedule = [];

        const loanAmount = loan.amount && loan.amount > 0
            ? loan.amount * (loan.investment_percentage / 100)
            : 0;
        const initialInterestRate = loan.interest_rate && loan.interest_rate > 0
            ? loan.interest_rate / 100
            : 0;
        const loanTerm = loan.loan_term && loan.loan_term > 0 ? loan.loan_term : 1;
        const changeInterestRateYear = loan.change_interest_rate_year && loan.change_interest_rate_year > 0
            ? loan.change_interest_rate_year
            : loanTerm;
        const newInterestRate = loan.change_interest_rate && loan.change_interest_rate >= 0
            ? loan.change_interest_rate / 100
            : initialInterestRate;

        const termMonths = loanTerm * 12;
        const holdingMonths = Math.min(holdingPeriod * 12, termMonths);

        let balance = loanAmount;
        let monthlyRate = initialInterestRate / 12;
        let monthlyPayment = this.calculateAnnuityPayment(loanAmount, monthlyRate, termMonths);

        for (let month = 1; month <= holdingMonths; month++) {
            if (month === (changeInterestRateYear * 12) + 1) {
                // Změna úrokové sazby
                const remainingTermMonths = termMonths - (month - 1);
                monthlyRate = newInterestRate / 12;
                monthlyPayment = this.calculateAnnuityPayment(balance, monthlyRate, remainingTermMonths);
            }

            const interestPayment = balance * monthlyRate;
            const principalPayment = monthlyPayment - interestPayment;
            balance -= principalPayment;

            schedule.push({
                month,
                totalPayment: monthlyPayment,
                principalPayment,
                interestPayment,
                remainingBalance: balance > 0 ? balance : 0,
            });
        }

        return schedule;
    },



    /**
     * Výpočet měsíční anuitní splátky úvěru.
     *
     * @param {number} loanAmount
     * @param {number} monthlyRate
     * @param {number} termMonths
     * @returns {number}
     */
    calculateAnnuityPayment(loanAmount, monthlyRate, termMonths) {
        if (loanAmount === 0 || termMonths === 0) {
            return 0;
        }

        if (monthlyRate === 0) {
            return loanAmount / termMonths;
        }

        // Standardní anuitní splátka
        return (
            (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, termMonths)) /
            (Math.pow(1 + monthlyRate, termMonths) - 1)
        );
    },

    /**
     * Výpočet celkových nákladů za dobu držení nemovitosti.
     *
     * @param {Object} property
     * @param {Number} year
     * @returns {number}
     */
    calculateTotalExpenses(property, year) {
        // Provozní náklady
        const totalOperatingExpenses = this.calculateOperatingExpenses(
            property,
            year
        );
        // Splátky úvěru
        const totalLoanPayments = this.calculateLoanPayments(
            property,
            year
        );

        return totalOperatingExpenses + totalLoanPayments;
    },

    /**
     * Výpočet zisku z prodeje nemovitosti.
     *
     * @param {Object} property
     * @param {number} holdingPeriod
     * @returns {number}
     */
    calculateSalesProfit(property, holdingPeriod) {
        // 1. Výpočet prodejní ceny po růstu hodnoty nemovitosti
        const finalPropertyValue =
            property.after_repair_value * Math.pow(1 + property.property_appreciation / 100, holdingPeriod);

        // 2. Výpočet zbývajícího zůstatku úvěru
        let remainingLoanBalance = 0;
        if (property.is_multiple_financing && property.financing && property.financing.length > 0) {
            for (const loan of property.financing) {
                remainingLoanBalance += this.calculateRemainingLoanBalanceAtSale(loan, holdingPeriod);
            }
        }

        // 3. Náklady na prodej (provize, poplatky)
        const sellingCosts = finalPropertyValue * (property.selling_costs / 100);

        // 4. Výpočet zisku z prodeje
        const salesProfit = finalPropertyValue - remainingLoanBalance - sellingCosts;

        // 5. Zaokrouhlení finálního zisku z prodeje
        return Math.round(salesProfit * 100) / 100;
    },

    /**
     * Výpočet zbývajícího zůstatku úvěru po uplynutí měsíců.
     *
     * @param {number} loanAmount
     * @param {number} monthlyRate
     * @param {number} monthsElapsed
     * @param {number} termMonths
     * @returns {number}
     */
    calculateRemainingLoanBalance(
        loanAmount,
        monthlyRate,
        monthsElapsed,
        termMonths
    ) {
        // Výpočet měsíční splátky bez pojištění (anuitní vzorec)
        const monthlyPayment =
            (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, termMonths)) /
            (Math.pow(1 + monthlyRate, termMonths) - 1);

        // Výpočet zbývajícího zůstatku úvěru po uplynutí měsíců
        const remainingLoanBalance =
            loanAmount * Math.pow(1 + monthlyRate, monthsElapsed) -
            (monthlyPayment * (Math.pow(1 + monthlyRate, monthsElapsed) - 1)) /
            monthlyRate;

        return Math.round(remainingLoanBalance * 100) / 100;
    },

    /**
     * Výpočet zbývajícího zůstatku úvěru při prodeji.
     *
     * @param {Object} loan
     * @param {number} holdingPeriod
     * @returns {number}
     */
    calculateRemainingLoanBalanceAtSale(loan, holdingPeriod) {
        const loanAmount =
            loan.amount && loan.amount > 0
                ? loan.amount * (loan.investment_percentage / 100)
                : 0;
        const initialInterestRate =
            loan.interest_rate && loan.interest_rate > 0
                ? loan.interest_rate / 100
                : 0;
        const loanTerm = loan.loan_term && loan.loan_term > 0 ? loan.loan_term : 1;
        const changeInterestRateYear =
            loan.change_interest_rate_year && loan.change_interest_rate_year > 0
                ? loan.change_interest_rate_year
                : loanTerm;
        const newInterestRate =
            loan.change_interest_rate && loan.change_interest_rate >= 0
                ? loan.change_interest_rate / 100
                : initialInterestRate;

        const termMonths = loanTerm * 12;
        const holdingMonths = holdingPeriod * 12;

        /*const monthlyInsurance =
            loan.monthly_insurance && loan.monthly_insurance >= 0
                ? loan.monthly_insurance / 100
                : 0;
        */
        // Výpočet zbývajícího zůstatku úvěru k datu prodeje
        let monthlyRate;
        if (holdingPeriod <= changeInterestRateYear) {
            monthlyRate = initialInterestRate / 12;
        } else {
            monthlyRate = newInterestRate / 12;
        }

        const remainingLoanBalance = this.calculateRemainingLoanBalance(
            loanAmount,
            monthlyRate,
            holdingMonths,
            termMonths
        );

        return remainingLoanBalance;
    },

    /**
     * Výpočet ROI.
     *
     * @param {Object} property
     * @param {number} holdingPeriod
     * @returns {number}
     */
    calculateROI(property, holdingPeriod) {
        const totalProfit = this.calculateTotalProfit(property, holdingPeriod);
        const initialInvestment = this.calculateInitialInvestment(property);

        if (initialInvestment === 0) {
            console.warn('Počáteční investice je nulová, ROI nelze spočítat.');
            return 0;
        }

        const roi = (totalProfit / initialInvestment) * 100;

        return Math.round(roi * 100) / 100;
    },

    /**
     * Výpočet celkového zisku.
     *
     * @param {Object} property
     * @param {number} holdingPeriod
     * @returns {number}
     */
    calculateTotalProfit(property, holdingPeriod) {
        // 1. Celkové příjmy
        const totalIncome = this.calculateTotalIncome(
            property,
            holdingPeriod
        );

        // 2. Celkové náklady
        const totalOperatingExpenses = this.calculateOperatingExpenses(
            property,
            holdingPeriod
        );
        const calculateLoanPayments = this.calculateLoanPayments(property, holdingPeriod);

        const totalExpenses = totalOperatingExpenses + calculateLoanPayments;

        // 3. Zisk z prodeje
        const salesProfit = this.calculateSalesProfit(property, holdingPeriod);

        // 4. Celkový zisk
        const initialInvestment = this.calculateInitialInvestment(property);

        const totalProfit =
            totalIncome + salesProfit - totalExpenses - initialInvestment;

        return Math.round(totalProfit * 100) / 100;
    },

    /**
     * Výpočet celkových úrokových plateb.
     *
     * @param {Object} property
     * @returns {number}
     */
    calculateTotalInterestPayments(property, holdingPeriod) {
        let totalInterestPayments = 0;

        if (
            property.is_multiple_financing &&
            property.financing &&
            property.financing.length > 0
        ) {
            for (const loan of property.financing) {
                const amortizationSchedule = this.generateAmortizationSchedule(loan, holdingPeriod);

                const totalInterestForLoan = amortizationSchedule.reduce((sum, payment) => sum + payment.interestPayment, 0);
                totalInterestPayments += totalInterestForLoan;
            }
        }

        return totalInterestPayments;
    },

    calculateTotalPrincipalPaid(property, holdingPeriod) {
        let totalPrincipalPaid = 0;

        if (
            property.is_multiple_financing &&
            property.financing &&
            property.financing.length > 0
        ) {
            for (const loan of property.financing) {
                const amortizationSchedule = this.generateAmortizationSchedule(loan, holdingPeriod);

                const totalPrincipalForLoan = amortizationSchedule.reduce((sum, payment) => sum + payment.principalPayment, 0);
                totalPrincipalPaid += totalPrincipalForLoan;
            }
        }

        return totalPrincipalPaid;
    },

    calculateLoanPaymentsForYear(property, year) {
        if (
            !property.is_multiple_financing ||
            !property.financing ||
            property.financing.length === 0
        ) {
            return 0;
        }

        let totalLoanPaymentsForYear = 0;

        for (const loan of property.financing) {
            const amortizationSchedule = this.generateAmortizationSchedule(loan, year);

            // Filtrujeme platby pro daný rok
            const paymentsForYear = amortizationSchedule.filter(payment => payment.month > (year - 1) * 12 && payment.month <= year * 12);

            const totalPaymentsForLoan = paymentsForYear.reduce((sum, payment) => sum + payment.totalPayment, 0);
            totalLoanPaymentsForYear += totalPaymentsForLoan;
        }

        return totalLoanPaymentsForYear;
    },

    calculateIncomeForYearWithGrowthRate(property, year, growthRate) {
        const grossRent = property.gross_rent;
        const vacancyRate = property.vacancy_rate / 100;
        const otherIncome = property.other_income.reduce((sum, income) => sum + income.amount, 0);

        const initialRentIncome = grossRent * 12 * (1 - vacancyRate); // Příjem z nájmu za první rok
        const initialOtherIncome = otherIncome * 12 * (1 - vacancyRate); // Ostatní příjmy za první rok

        // Výpočet příjmů pro zvolený rok s aplikací růstové sazby
        const incomeForYear = (initialRentIncome + initialOtherIncome) * Math.pow(1 + growthRate, year - 1);

        return incomeForYear;
    },





    /**
     * Výpočet cash flow pro konkrétní rok.
     *
     * @param {Object} property
     * @param {number} year
     * @returns {number}
     */
    calculateCashFlowForYear(property, year) {
        // 1. Celkové příjmy
        const totalIncome = this.calculateTotalIncome(property, year);

        // 2. Operativní náklady
        const operatingExpenses = this.calculateOperatingExpenses(property, year);

        // 3. Splátky úvěru
        const loanPayments = this.calculateLoanPayments(property, year);

        // 4. Cash flow
        const cashFlow = totalIncome - operatingExpenses - loanPayments;

        return Math.round(cashFlow * 100) / 100;
    },

    /**
     * Výpočet daně dle české legislativy na základě celkového příjmu pro daný rok.
     *
     * @param {Object} property - Objekt nemovitosti.
     * @param {number} year - Rok, pro který chceme daň spočítat.
     * @returns {number} Daň z celkového příjmu za daný rok.
     */
    calculateTaxForYear(property, year) {
        // Získáme celkový příjem pomocí metody calculateTotalIncome
        const totalIncome = this.calculateTotalIncome(property, year);

        // Paušální výdaje - 30 % z příjmů (property_tax_rate bude 30 % pro paušál)
        const flatExpenses = totalIncome * (property.property_tax_rate / 100);

        // Daňový základ po odečtení paušálních výdajů
        const taxableIncome = totalIncome - flatExpenses;

        // Vypočítáme daň (např. 15 %)
        const tax = taxableIncome * (property.tax_rate / 100);

        return Math.round(tax * 100) / 100; // Vrátíme vypočítanou daň, zaokrouhlenou na dvě desetinná místa
    },

    /**
     * Výpočet cashflow po odečtení daně dle české legislativy.
     *
     * @param {Object} property - Objekt nemovitosti.
     * @param {number} year - Rok, pro který chceme cashflow po zdanění spočítat.
     * @returns {number} Cashflow po zdanění za daný rok.
     */
    calculateCashFlowAfterTax(property, year) {
        // Získáme původní cashflow pomocí existující metody
        const cashFlowBeforeTax = this.calculateCashFlowForYear(property, year);

        // Získáme daň pro daný rok pomocí metody calculateTaxForYear
        const tax = this.calculateTaxForYear(property, year);

        // Vypočítáme cashflow po odečtení daně
        const cashFlowAfterTax = cashFlowBeforeTax - tax;

        return Math.round(cashFlowAfterTax * 100) / 100; // Vrátíme výsledek zaokrouhlený na dvě desetinná místa
    },




    /**
     * Výpočet cash flow pro více let s daným intervalem.
     *
     * @param {Object} property
     * @param {number} years
     * @param {number} interval
     * @returns {Array<number>}
     */
    calculateCashFlowForYears(property, years, interval) {
        const cashFlows = [];

        for (let year = interval; year <= years; year += interval) {
            const cashFlowForYear = this.calculateCashFlowForYear(property, year);
            cashFlows.push(cashFlowForYear);
        }

        return cashFlows;
    },

    /**
     * Výpočet čisté současné hodnoty (NPV) pro dané cashflow a diskontní sazbu.
     * @param {Array<number>} cashFlows - Pole cashflow v čase (včetně počáteční investice jako záporné číslo).
     * @param {number} discountRate - Diskontní sazba.
     * @returns {number} NPV - čistá současná hodnota.
     */
    calculateNPV(cashFlows, discountRate) {
        if (!Array.isArray(cashFlows)) {
            console.error('CashFlows is not an array:', cashFlows);
            return 0;
        }

        return cashFlows.reduce((npv, cashFlow, year) => {
            return npv + cashFlow / Math.pow(1 + discountRate, year);
        }, 0);
    },


    /**
     * Výpočet IRR (vnitřní míry návratnosti) metodou bisekce.
     * @param {Array<number>} cashFlows - Pole cashflow (včetně počáteční investice jako záporné číslo).
     * @param {number} precision - Přesnost výpočtu (např. 0.00001).
     * @returns {number} IRR - vnitřní míra návratnosti.
     */
    calculateIRR(cashFlows, precision = 0.00001) {
        let lowerBound = -1;
        let upperBound = 1;
        let irr = 0;

        while (upperBound - lowerBound > precision) {
            irr = (lowerBound + upperBound) / 2;
            const npv = this.calculateNPV(cashFlows, irr);

            if (npv > 0) {
                lowerBound = irr;
            } else {
                upperBound = irr;
            }
        }

        return Math.round(irr * 10000) / 100;
    },

    /**
     * Výpočet IRR pro investici do nemovitosti.
     *
     * @param {Object} property - Objekt nemovitosti.
     * @param {number} holdingPeriod - Doba držení investice.
     * @returns {number} IRR - vnitřní míra návratnosti.
     */
    calculatePropertyIRR(property, holdingPeriod) {
        const initialInvestment = -this.calculateInitialInvestment(property);

        const cashFlows = [initialInvestment];

        for (let year = 1; year <= holdingPeriod; year++) {
            const cashFlowForYear = this.calculateCashFlowForYear(property, year);
            cashFlows.push(cashFlowForYear);
        }

        const salesProfit = this.calculateSalesProfit(property, holdingPeriod);
        cashFlows[holdingPeriod] += salesProfit;

        return this.calculateIRR(cashFlows);
    },

    /**
     * Výpočet ROE (návratnost vlastního kapitálu).
     *
     * @param {Object} property - Objekt nemovitosti.
     * @param {number} year - Rok, pro který chceme ROE spočítat.
     * @returns {number} ROE - návratnost vlastního kapitálu v procentech.
     */
    calculateROE(property, year) {
        const yearlyCashFlow = this.calculateCashFlowForYear(property, year);

        const purchasePrice = property.purchase_price;
        const appreciationRate = property.property_appreciation / 100 || 0; // Zhodnocení např. 2 % ročně
        const appreciatedValue = purchasePrice * Math.pow(1 + appreciationRate, year);

        const remainingLoanBalance = this.calculateRemainingLoanBalanceAtSale(property.financing[0], year);

        const equityAtYearEnd = appreciatedValue - remainingLoanBalance;

        if (equityAtYearEnd <= 0) {
            console.warn('Vlastní kapitál na konci roku je nulový nebo záporný, ROE nelze spočítat.');
            return 0;
        }

        const roe = (yearlyCashFlow / equityAtYearEnd) * 100;

        return Math.round(roe * 100) / 100;
    },


    /**
     * Výpočet BER (Break-Even Ratio).
     *
     * @param {Object} property - Objekt nemovitosti.
     * @param {number} year - Rok, pro který chceme BER spočítat.
     * @returns {number} BER - break-even ratio v procentech.
     */
    calculateBER(property, year) {
        // Roční provozní náklady
        const annualOperatingExpenses = this.calculateOperatingExpenses(property, year);

        // Roční splátky úvěru (dluhová služba)
        const annualLoanPayments = this.calculateLoanPayments(property, year);

        // Roční hrubé nájemné
        const grossRent = property.gross_rent * 12; // Předpokládáme roční příjem z nájmu bez zohlednění neobsazenosti

        if (grossRent === 0) {
            console.warn('Roční hrubé nájemné je nulové, BER nelze spočítat.');
            return 0;
        }

        // BER = (Roční provozní náklady + Roční dluhová služba) / Roční hrubé nájemné * 100
        const ber = ((annualOperatingExpenses + annualLoanPayments) / grossRent) * 100;

        return Math.round(ber * 100) / 100;
    },

    calculateTotalInvestedCash(property, holdingPeriod) {
        const initialInvestment = this.calculateInitialInvestment(property);
        let totalAdditionalCashInvested = 0;

        for (let year = 1; year <= holdingPeriod; year++) {
            const income = this.calculateIncomeForYear(property, year);

            const operatingExpenses = this.calculateOperatingExpensesForYear(property, year);

            const loanPayments = this.calculateLoanPaymentsForYear(property, year);

            const cashFlowBeforeTax = income - operatingExpenses - loanPayments;

            if (cashFlowBeforeTax < 0) {
                totalAdditionalCashInvested += -cashFlowBeforeTax;
            } else {
                break;
            }
        }

        return initialInvestment + totalAdditionalCashInvested;
    },

    calculateTotalInvestedCashOverTime(property, holdingPeriod) {
        const investmentsOverTime = [];
        let totalAdditionalCashInvested = 0;
        let initialInvestment = this.calculateInitialInvestment(property);

        for (let year = 1; year <= holdingPeriod; year++) {
            const income = this.calculateIncomeForYear(property, year);
            const operatingExpenses = this.calculateOperatingExpensesForYear(property, year);
            const loanPayments = this.calculateLoanPaymentsForYear(property, year);
            const cashFlowBeforeTax = income - operatingExpenses - loanPayments;

            if (cashFlowBeforeTax < 0) {
                totalAdditionalCashInvested += -cashFlowBeforeTax;
            }

            investmentsOverTime.push(initialInvestment + totalAdditionalCashInvested);
        }

        return investmentsOverTime;
    },


    calculateIncomeForYear(property, year) {
        const grossRent = property.gross_rent;
        const vacancyRate = property.vacancy_rate / 100;
        const otherIncome = property.other_income.reduce(
            (sum, income) => sum + income.amount,
            0
        );
        const incomeGrowth = property.income_growth / 100;

        const initialRentIncome = grossRent * 12 * (1 - vacancyRate);
        const initialOtherIncome = otherIncome * 12 * (1 - vacancyRate);
        const totalIncomeFirstYear = initialRentIncome + initialOtherIncome;

        // Výpočet příjmu pro daný rok
        const incomeForYear = totalIncomeFirstYear * Math.pow(1 + incomeGrowth, year - 1);

        return incomeForYear;
    },

    calculateOperatingExpensesForYear(property, year) {
        const expenseGrowth = property.expense_growth / 100;
        const initialOperatingExpenses =
            property.operating_expenses.reduce(
                (sum, expense) => sum + expense.amount,
                0
            ) * 12;

        // Výpočet provozních nákladů pro daný rok
        const operatingExpensesForYear =
            initialOperatingExpenses * Math.pow(1 + expenseGrowth, year - 1);

        return operatingExpensesForYear;
    },


    // PŘIDÁNO JEN KVŮLI CASHFLOW, OVĚŘIT, ŽE NEMÁM ALETRNATIVY

    calculatePropertyValueForYear(property, year) {
        const appreciationRate = property.property_appreciation / 100 || 0;
        const propertyValue = property.after_repair_value * Math.pow(1 + appreciationRate, year);
        return propertyValue;
    },

    calculateLoanBalanceForYear(property, year) {
        let totalFinancedAmount = 0;
        let totalPrincipalPaid = this.calculateTotalPrincipalPaid(property, year);

        if (property.is_multiple_financing && property.financing && property.financing.length > 0) {
            for (const loan of property.financing) {
                const financedAmount = loan.amount * (loan.investment_percentage / 100);
                totalFinancedAmount += financedAmount;
            }
        }
        let remainingLoanBalance = totalFinancedAmount - totalPrincipalPaid;

        return remainingLoanBalance > 0 ? remainingLoanBalance : 0;
    },


    calculateGrossRentForYear(property, year) {
        const growthRate = 1 + property.income_growth / 100;
        return property.gross_rent * 12 * Math.pow(growthRate, year - 1);
    },

    calculateVacancyLossForYear(property, year) {
        return this.calculateGrossRentForYear(property, year) * (property.vacancy_rate / 100);
    },

    calculateOtherIncomeForYear(property, year, income) {
        const growthRate = 1 + property.income_growth / 100;
        return income.amount * 12 * Math.pow(growthRate, year - 1);
    },

    calculateEffectiveGrossIncomeForYear(property, year) {
        const grossRent = this.calculateGrossRentForYear(property, year);
        const vacancyLoss = this.calculateVacancyLossForYear(property, year);
        const otherIncomeTotal = property.other_income.reduce((sum, income) => {
            return sum + this.calculateOtherIncomeForYear(property, year, income);
        }, 0);
        return grossRent - vacancyLoss + otherIncomeTotal;
    },

    // Náklady
    calculateExpenseForYear(property, year, expense) {
        const growthRate = 1 + property.expense_growth / 100;
        return expense.amount * 12 * Math.pow(growthRate, year - 1);
    },

    // NOI
    calculateNetOperatingIncomeForYear(property, year) {
        const effectiveGrossIncome = this.calculateEffectiveGrossIncomeForYear(property, year);
        const operatingExpenses = this.calculateOperatingExpensesForYear(property, year);
        return effectiveGrossIncome - operatingExpenses;
    },

    // Úvěr
    calculateInterestExpenseForYear(property, year) {
        return this.calculateTotalInterestPayments(property, year);
    },

    calculatePrincipalPaymentForYear(property, year) {
        return this.calculateTotalPrincipalPaid(property, year);
    },

    calculateTotalDebtServiceForYear(property, year) {
        return (
            this.calculateInterestExpenseForYear(property, year) +
            this.calculatePrincipalPaymentForYear(property, year)
        );
    },


    // Analýza prodeje
    calculateSellingCostsForYear(property, year) {
        const propertyValue = this.calculatePropertyValueForYear(property, year);
        return propertyValue * (property.selling_costs / 100);
    },

    calculateNetSaleProceedsForYear(property, year) {
        const propertyValue = this.calculatePropertyValueForYear(property, year);
        const sellingCosts = this.calculateSellingCostsForYear(property, year);
        const loanBalance = this.calculateLoanBalanceForYear(property, year);
        return propertyValue - sellingCosts - loanBalance;
    },


    calculateCumulativeCashFlow(property, year) {
        let total = 0;
        for (let y = 1; y <= year; y++) {
            total += this.calculateCashFlowAfterTax(property, y);
        }
        return total;
    },





};
