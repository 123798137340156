<template>
  <div class="space-y-8">
    <!-- Kupní cena nemovitosti -->
    <div class="w-full mt-3">
      <h3 class="text-xl pl-2 font-semibold text-gray-800 mb-1">
        <font-awesome-icon icon="sack-dollar" size="xs"/>
        Základní údaje o nemovitosti
      </h3>
      <div class="bg-gray-100 p-4 rounded-lg space-y-4">
        <div>
          <label class="block text-gray-700 font-semibold">Kupní cena nemovitosti (Kč):</label>
          <div class="relative">
            <input
                v-model="formattedPurchasePrice"
                @input="updatePurchasePrice"
                @change="updatePriceForFinancing"
                type="text"
                placeholder="Např. 3 000 000"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
            >
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
          </div>
          <p class="text-sm text-gray-500">Cena samostatné nemovitosti</p>
        </div>

        <acquisition-costs v-model="form.other_initial_costs"/>
      </div>
    </div>

    <rehab-costs
        :is-multiple-repair="form.is_multiple_repair"
        v-model="rehabCosts"
        @toggleRehabCosts="toggleRehabCosts"
    />

    <div class="w-full mt-3">
      <h3 class="text-xl pl-2 font-semibold text-gray-800 mb-1">
        <font-awesome-icon icon="money-bill-trend-up" size="xs"/>
        Hodnota nemovitosti po rekonstrukci
      </h3>
      <div class="bg-gray-100 p-4 rounded-lg space-y-4">
        <div>
          <label class="block text-gray-700 font-semibold">Hodnota nemovitosti po rekonstrukci (Kč):</label>
          <div class="relative">
            <input
                v-model="formattedAfterRepairValue"
                @input="updateAfterRepairValue"
                @change="updateAfterRepairValue"
                type="text"
                placeholder="Např. 3 000 000"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
            >
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Kč</span>
          </div>
          <p class="text-sm text-gray-500">Jakou očekáváte, že bude mít nemovitost hodnotu po provedení rekonstrukce.</p>
        </div>
      </div>
    </div>


    <financing-items
        v-model="form.financing"
        :isMultipleFinancing="form.is_multiple_financing"
        @update:isMultipleFinancing="form.is_multiple_financing = $event"
    />

  </div>
</template>

<script>
import AcquisitionCosts from "@/components/forms-items/AcquisitionCosts.vue";
import RehabCosts from "@/components/forms-items/RehabCosts.vue";
import FinancingItems from "@/components/forms-items/FinancingItems.vue";

export default {
  name: "PurchaseAndRehab",
  components: {FinancingItems, RehabCosts, AcquisitionCosts},

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    propertyCategory:{
      type: String,
      required: true,
      default: 'rent'
    }
  },

  created() {
    if(this.form.repair_costs){
      this.rehabCosts = this.form.repair_costs;
    }
    if(this.form.financing){
      this.financingDetails = this.form.financing;
    }
  },

  watch: {
    rehabCosts: {
      handler(newVal) {
        this.form.repair_costs = newVal;
      },
      deep: true
    },
    financingDetails: {
      handler(newVal) {
        this.form.financing = newVal;
      },
      deep: true
    },
    form: {
      handler(newVal) {
        this.financingDetails = newVal.financing;
      },
      deep: true
    }
  },

  data() {
    return {
      rehabCosts: [
        { name: 'Náklady na rekonstrukci', amount: null }
      ],
      financingDetails: [
        {
          name: 'Financování',
          amount: 0,
          investment_percentage: 0,
          loan_term: 0,
          interest_rate: 0,
          monthly_insurance: 0
        }
      ]
    };
  },

  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },

    formattedPurchasePrice() {
      return this.form.purchase_price != null ? this.form.purchase_price.toLocaleString('cs-CZ') : '';
    },

    formattedAfterRepairValue() {
      return this.form.after_repair_value != null ? this.form.after_repair_value.toLocaleString('cs-CZ') : '';
    },
    formattedLotSize() {
      return this.form.other_initial_costs != null ? this.form.other_initial_costs.toLocaleString('cs-CZ') : '';
    },
    rehabCostSingle() {
      return this.rehabCosts[0].amount != null ? this.rehabCosts[0].amount.toLocaleString('cs-CZ') : '';
    }
  },
  methods: {
    toggleRehabCosts() {
      this.form.is_multiple_repair = !this.form.is_multiple_repair;
    },
    addRehabCost() {
      this.rehabCosts.push({name: '', amount: null});
    },

    removeRehabCost(index) {
      if(this.rehabCosts.length > 1){
        this.rehabCosts.splice(index, 1);
      }
    },

    updateRehabCostSingle(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.rehabCosts[0].amount = parseInt(value, 10) || 0;
    },

    addFinancingDetail() {
      this.financingDetails.push({
        name: 'Další možnost financování',
        amount: 0,
        investment_percentage: 0,
        loan_term: 0,
        interest_rate: 0,
        monthly_insurance: 0
      });
    },
    removeFinancingDetail(index) {
      this.financingDetails.splice(index, 1);
    },

    updatePriceForFinancing(){
      if(!this.form.financing[0].amount && this.form.is_multiple_financing){
        this.form.financing[0].amount = this.form.purchase_price;
      }
    },

    updatePurchasePrice(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.form.purchase_price = parseInt(value, 10) || 0;
    },
    updateAfterRepairValue(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.form.after_repair_value = parseInt(value, 10) || 0;
    },
    updateLotSize(event) {
      const value = event.target.value.replace(/\s/g, '');
      this.form.other_initial_costs = parseInt(value, 10) || 0;
    },
    formatCurrency(value) {
      return value != null ? value.toLocaleString('cs-CZ') : '';
    },
    updateRehabCost(index, value) {
      const numericValue = parseInt(value.replace(/\s/g, ''), 10) || 0;
      this.rehabCosts[index].amount = numericValue;
    },
    updateLoanAmount(index, value) {
      const numericValue = parseInt(value.replace(/\s/g, ''), 10) || 0;
      this.financingDetails[index].amount = numericValue;
    },
    updateInsurance(index, value) {
      const numericValue = parseInt(value.replace(/\s/g, ''), 10) || 0;
      this.financingDetails[index].monthly_insurance = numericValue;
    },
  }
};
</script>
