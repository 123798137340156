<template>
  <div class="p-0 mt-2">
    <div class="bg-white p-6 rounded-lg">
    <h2 class="text-2xl font-bold text-sky-700 mb-5">Data pro 1. rok investice</h2>
      <div :class="isCompare ? 'grid grid-cols-1 sm:grid-cols-2 gap-6 text-center bg-white' : 'grid grid-cols-1 md:grid-cols-3 gap-6 text-center bg-white'">

        <!-- Cash Flow Box -->
        <div class="p-4 rounded-lg shadow-md" v-if="allowCategories(['rent', 'brrr', ''])">
          <h3 class="text-lg font-bold mb-2">Cash Flow</h3>
          <p class="text-3xl font-semibold" :class="{ 'text-red-600': data.cashflow < 0, 'text-sky-800': data.cashflow >= 0 }">{{ formatCurrency(data.cashflow) }}</p>
          <p class="text-sm text-gray-600 mt-1">Roční příjem z nemovitosti v 1. roce (bez daně)</p>
        </div>

        <div class="p-4 rounded-lg shadow-md">
          <h3 class="text-lg font-bold mb-2">Cap Rate</h3>
          <p class="text-3xl font-semibold text-sky-800"
             :class="{ 'text-red-600': capRate < 0, 'text-sky-800': capRate >= 0 }"
          >{{ safeToFixed(capRate, 2) }}%</p>
          <p class="text-sm text-gray-600 mt-1">Míra kapitalizace (bez daně)</p>
        </div>

        <!-- Potřebná Hotovost Box -->
        <div class="p-4 rounded-lg shadow-md">
          <h3 class="text-lg font-bold mb-2">Potřebná hotovost</h3>
          <p class="text-3xl font-semibold"
             :class="{ 'text-red-600': data.cash_required < 0, 'text-sky-800': data.cash_required >= 0 }"
          >{{ formatCurrency(safeToFixed(data.cash_required, 0)) }}
          </p>
          <p class="text-sm text-gray-600 mt-1">Celková částka potřebná na nákup a rekonstrukci </p>
        </div>

        <!-- ROI Box -->
        <div class="p-4 rounded-lg shadow-md">
          <h3 class="text-lg font-bold mb-2">ROI</h3>
          <p class="text-3xl font-semibold" :class="{ 'text-red-600': ROI < 0, 'text-sky-800': ROI >= 0 }">{{ ROI }}%</p>
          <p class="text-sm text-gray-600 mt-1">Návratnost investice pro 1. rok</p>
        </div>

        <!-- Yield Box -->
        <div class="p-4 rounded-lg shadow-md">
          <h3 class="text-lg font-bold mb-2">Yield</h3>
          <p class="text-3xl font-semibold" :class="{ 'text-red-600': data.yield < 0, 'text-sky-800': data.yield >= 0 }">
            {{ safeToFixed(data.yield) }}%
          </p>
          <p class="text-sm text-gray-600 mt-1">Příjem vs hodnota nemovitosti</p>
        </div>

        <div class="p-4 rounded-lg shadow-md">
          <h3 class="text-lg font-bold mb-2">CoC</h3>
          <p class="text-3xl font-semibold" :class="{ 'text-red-600': COC < 0, 'text-sky-800': COC >= 0 }">
            {{ safeToFixed(COC) }}%
          </p>
          <p class="text-sm text-gray-600 mt-1">Návratnost na vložený kapitál</p>
        </div>
      </div>
    </div>

    <div class=" mt-5 bg-white p-6 rounded-lg">
      <h2 class="text-2xl font-bold text-sky-700">Vaše kritéria</h2>
      <p class="text-sm text-gray-600 mb-6 mt-3"> Zde vidíte Vámi nastavená kritéria pro výběr nemovitostí.
        Kritéria můžete <router-link :to="{name: 'LimitsSetting'}" class="text-sky-600 underline"> upravovat zde. </router-link></p>
        <div class="grid grid-cols-2 gap-y-6 mt-5" v-if="criteriaCashFlowAllow">
          <div class="flex items-center">
              <img src="/images/icons/check.png"
                   class="w-8"
                   alt="check"
                   v-if="criteriaCashFlow"
              >
              <img src="/images/icons/not-check.png"
                   class="w-8"
                   alt="check"
                   v-else
              >
            <p class="ml-2">
               Cash Flow je minimálně
               {{formatCurrency(criteria.cashflow_value)}}
              </p>
          </div>
          <div class="flex items-center" v-if="criteriaCashAllow">
            <img src="/images/icons/check.png"
                 class="w-8"
                 alt="check"
                 v-if="criteriaCash"
            >
            <img src="/images/icons/not-check.png"
                 class="w-8"
                 alt="check"
                 v-else
            >
            <p class="ml-2">
              Maximální potřebná hotovost
              {{formatCurrency(criteria.cash_value)}}
            </p>
          </div>
          <div class="flex items-center" v-if="criteriaRoiAllow">
            <img src="/images/icons/check.png"
                 class="w-8"
                 alt="check"
                 v-if="criteriaRoi"
            >
            <img src="/images/icons/not-check.png"
                 class="w-8"
                 alt="check"
                 v-else
            >
            <p class="ml-2">
              Minimální průměrný ROI
              {{criteria.roi_value}} % p.a.
            </p>
          </div>
          <div class="flex items-center" v-if="criteriaYieldAllow">
            <img src="/images/icons/check.png"
                 class="w-8"
                 alt="check"
                 v-if="criteriaYield"
            >
            <img src="/images/icons/not-check.png"
                 class="w-8"
                 alt="check"
                 v-else
            >
            <p class="ml-2">
              Minimální Yield v prvním roce
              {{ criteria.yield_value}} %
            </p>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import {useAiDataStore} from "@/pinia/useAiDataStore";
import {usePropertyStore} from "@/pinia/usePropertyStore";
import { InvestmentService } from '@/functions/investmentService.js';

export default {
  name: "MainAnalysis",

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    isCompare: {
      type:Boolean,
      default: () => false,
    }
  },

  async mounted() {
    const {data, cleanRevenueYear, YIELD, capRate, COC, justMyInvestment} = this;
    const aiStore = useAiDataStore();
    const propertyStore = usePropertyStore();

    if(propertyStore.criteria === null){
      await propertyStore.fetchCriteria(this.$axios);
    }

    this.criteria = propertyStore.criteria;

    aiStore.saveDataToCategory(
        'analysisCategoryOne',
        this.data.id,
        'Název:' + data.name + ';' +
        'Dispozice:' + data.disposition + ';' +
        'Nájemné: ' + data.gross_rent + ';' +
        'Adresa:' + data.address + ';' +
        'Pořizovací cena:' + data.address + 'Kč;' +
        'Podlahová plocha:' + data.square_meters +';' +
        'CashFlow:' + cleanRevenueYear ? cleanRevenueYear.toFixed(1) : 0 + ';' +
        'Yield:' + YIELD ? YIELD.toFixed(1) : 0  + ';' +
        'Cap Rate:' + capRate ? capRate.toFixed(1) : 0 + ';' +
        'CoC:' + COC ? COC.toFixed(1) : 0 + ';' +
        'Nutná hotovost pro nákup:' + justMyInvestment + 'Kč;'
    );
  },

  data() {
    return {
      criteria: null,
    }
  },
  computed: {
    data() {
      return this.modelValue;
    },


    /** Všechen hrubý příjem z nemovitosti, nějak neočištěný, maximum co bych z nemovitosti ročně třískal */
    justAllIncomeYear(){
      const rentIncome = (this.data.gross_rent * 12) - ( (this.data.gross_rent * 12 / 100) * this.data.vacancy_rate);
      let otherIncomeSum = 0;

      this.data.other_income.forEach((item) =>{
        otherIncomeSum += item.amount;
      })

      otherIncomeSum *= 12;

      return rentIncome + otherIncomeSum;
    },

    /** Roční platba na hypotéce i s pojištěním */
    financingAllPaymentYear() {
      if (!this.data.is_multiple_financing) return 0;

      if (!this.data.financing || !Array.isArray(this.data.financing)) return null;

      return this.data.financing.reduce((total, finance) => {
        const P = finance.amount * (finance.investment_percentage / 100);
        const annualRate = finance.interest_rate / 100;
        const monthlyRate = annualRate / 12;
        const termMonths = finance.loan_term * 12;
        const insuranceMultiplier = 1 + (finance.monthly_insurance / 100);

        const monthlyPayment = P * monthlyRate * Math.pow(1 + monthlyRate, termMonths) /
            (Math.pow(1 + monthlyRate, termMonths) - 1);

        const monthlyPaymentWithInsurance = monthlyPayment * insuranceMultiplier;

        const annualPayment = monthlyPaymentWithInsurance * 12;

        return total + annualPayment;
      }, 0);
    },


    /*** Moje investované peníze, které do toho vložím */
    justMyInvestment() {
      if (!this.data.financing || !Array.isArray(this.data.financing)) return null;

      const totalFinanced = this.data.financing.reduce((total, finance) => {
        const financedAmount = finance.amount * (finance.investment_percentage / 100);
        return total + financedAmount;
      }, 0);

      const purchasePrice = (this.data.purchase_price || 0 ) + this.data.other_initial_costs;

      const reconstruction = this.data.repair_costs;
      let sumrec = 0;
      reconstruction.forEach(item =>{
        sumrec += item.amount;
      })

      return purchasePrice - totalFinanced + sumrec;
    },

    /*** Počáteční investice */
    initialInvestment(){
      const value = this.data.purchase_price + this.data.other_initial_costs;

      let reconstruction = 0;

      if(this.data.repair_costs.length > 0){
        this.data.repair_costs.forEach((item) =>{
          reconstruction += item.amount;
        })
      }
      return value + reconstruction;
    },



    /** Všechny provozní náklady na nemovitost (roční) */
    operatingCostsPerYear(){
      let value = 0;

      if(this.data.operating_expenses.length > 0){
        this.data.operating_expenses.forEach((item) =>{
          value += item.amount;
        })
      }
      return value * 12;
    },


    /*** Roční čistý příjem z nemovitosti (CashFlow)*/
    cleanRevenueYear(){
      return this.justAllIncomeYear - this.financingAllPaymentYear - this.operatingCostsPerYear;
    },

    // Výpočet Yield
    YIELD() {
      return (this.justAllIncomeYear / this.initialInvestment) * 100;
    },

    /**** Cap rate **/

    capRate(){
      let value;
      if(this.data.after_repair_value){
         value = this.data.after_repair_value;
      }else if(this.data.purchase_price){
         value = this.data.purchase_price;
      }else{
        return 0;
      }
      const naklady = InvestmentService.calculateOperatingExpenses(this.data, 1);
      const vynosy = InvestmentService.calculateTotalIncome(this.data, 1);
      return ( (vynosy - naklady) / value) *100;
    },

    /**** COC **/
    COC(){
      return (this.data.cashflow / this.data.cash_required)*100;
    },

    ROI(){
      if(this.data){
        return InvestmentService.calculateROI(this.data, 1);
      }else{
        return 0;
      }
    },

    // Potřebná hotovost
    requiredCash() {
      const downPayment = this.data.purchase_price * ((100 - this.data.financing[0]?.investment_percentage) / 100);
      return downPayment + this.totalRepairCosts;
    },

    // Celkové náklady na opravy
    totalRepairCosts() {
      return this.data.repair_costs.reduce((total, cost) => total + cost.amount, 0);
    },

    criteriaCashAllow(){
      return this.criteria && Boolean(this.criteria.is_cash_allow) && this.criteria.cash_value !== null;
    },

    criteriaCashFlowAllow(){
      return this.criteria && Boolean(this.criteria.is_cashflow_allow) && this.criteria.cashflow_value !== null;
    },

    criteriaRoiAllow(){
      return this.criteria && Boolean(this.criteria.is_roi_allow) && this.criteria.roi_value !== null;
    },

    criteriaYieldAllow(){
      return this.criteria && Boolean(this.criteria.is_yield_allow) && this.criteria.yield_value !== null;
    },

    criteriaCash(){
      return (this.data.cash_required < this.criteria.cash_value);
    },

    criteriaCashFlow(){
      return (this.data.cashflow > this.criteria.cashflow_value);
    },

    criteriaRoi(){
      return (this.data.average_annual_valuation > this.criteria.roi_value);
    },
    criteriaYield(){
      return (this.data.yield > this.criteria.yield_value);
    },
  },

  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'CZK'}).format(value);
    },
    allowCategories(array){
      console.log(array);
      return true;
      //return !!array.contains(this.data.categories);
    },
    safeToFixed(value, digits = 2) {
      if (value === null || value === undefined || isNaN(value)) {
        return "0.00";
      }
      return Number(value).toFixed(digits);
    },
  }
}
</script>
