<template>
  <div class="bg-white p-6 max-w-full overflow-hidden">
    <div class="flex w-full border-b-2 mb-11">
      <div class="w-1/2">
        <h2 class="text-2xl font-bold text-sky-700 mb-6">Projekce</h2>
      </div>

    <!-- Výběr pro zobrazení roků -->
    <div class="flex gap-16 mb-5  w-1/2">
      <div class="w-full">
        <label class="block mb-2 text-gray-700">Zobrazovat:</label>
        <select v-model="selectedInterval" class="p-2 border rounded-md w-full">
          <option value="milestones">Milníky</option>
          <option value="1">Každý rok</option>
          <option value="5">Po 5 letech</option>
          <option value="10">Po 10 letech</option>
        </select>
      </div>
    </div>

    </div>
    <!-- Tabulkové zobrazení cash flow -->
    <div class="table-container max-w-full overflow-x-auto no-scrollbar">
      <table class="table-auto w-full border-collapse no-scrollbar" style="width: auto; max-width: 90%; overflow-x: auto;">
        <thead>
        <tr>
          <th class="p-4" style="min-width: 250px"></th>
          <th v-for="year in filteredYears" :key="year" class="p-4 text-right">Rok {{ year }}</th>
        </tr>
        </thead>
        <tbody>
        <!-- Výnosy -->
        <tr class="text-sky-500 font-semibold">
          <td colspan="100%" class="p-4">Výnosy</td>
        </tr>
        <!-- Hrubý nájem -->
        <tr>
          <td class="p-4">Hrubý nájem:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">{{ formatCurrency(grossRentForYear(year)) }}</td>
        </tr>
        <!-- Neobsazenost -->
        <tr>
          <td class="p-4">Neobsazenost:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">-{{ formatCurrency(vacancyLossForYear(year)) }}</td>
        </tr>
        <!-- Ostatní příjmy -->
        <tr v-for="income in data.other_income" :key="income.id">
          <td class="p-4">{{ income.name }}:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">{{ formatCurrency(otherIncomeForYear(year, income)) }}</td>
        </tr>
        <!-- Provozní příjem -->
        <tr class="border-t-2 border-gray-300 font-bold">
          <td class="p-4">Provozní příjem celkem:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">{{ formatCurrency(effectiveGrossIncomeForYear(year)) }}</td>
        </tr>

        <!-- Náklady -->
        <tr class="text-sky-500 font-semibold">
          <td colspan="100%" class="p-4">Provozní náklady</td>
        </tr>
        <tr v-for="expense in data.operating_expenses" :key="expense.id">
          <td class="p-4">{{ expense.name }}:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">-{{ formatCurrency(expenseForYear(year, expense)) }}</td>
        </tr>
        <tr class="border-t-2 border-gray-300 font-bold">
          <td class="p-4">Provozní náklady celkem:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">-{{ formatCurrency(totalOperatingExpensesForYear(year)) }}</td>
        </tr>
        <!-- Čistý provozní příjem (NOI) -->
        <tr class="border-t-2 border-gray-300 font-bold">
          <td class="p-4">Čistý provozní příjem (NOI):</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">{{ formatCurrency(netOperatingIncomeForYear(year)) }}</td>
        </tr>

        <!-- Úvěr -->
        <tr class="text-sky-500 font-semibold">
          <td colspan="100%" class="p-4">Úvěrové náklady</td>
        </tr>
        <tr>
          <td class="p-4">Úroky z úvěru:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">-{{ formatCurrency(interestExpenseForYear(year)) }}</td>
        </tr>
        <tr>
          <td class="p-4">Splátky jistiny:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">-{{ formatCurrency(principalPaymentForYear(year)) }}</td>
        </tr>
        <tr class="border-t-2 border-gray-300 font-bold">
          <td class="p-4">Úvěrové náklady celkem:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">-{{ formatCurrency(totalDebtServiceForYear(year)) }}</td>
        </tr>

        <!-- Peněžní tok -->
        <tr class="text-sky-500 font-semibold">
          <td colspan="100%" class="p-4">Peněžní tok</td>
        </tr>
        <tr class="border-t-2 border-gray-300 font-bold">
          <td class="p-4">Peněžní tok před zdaněním:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">{{ formatCurrency(cashFlowBeforeTaxForYear(year)) }}</td>
        </tr>
        <tr>
          <td class="p-4">Daň z příjmu:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">-{{ formatCurrency(taxForYear(year)) }}</td>
        </tr>
        <tr class="border-t-2 border-gray-300 font-bold">
          <td class="p-4">Peněžní tok po zdanění:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">{{ formatCurrency(cashFlowAfterTaxForYear(year)) }}</td>
        </tr>
        <tr class="border-t-2 border-gray-300 font-bold">
          <td class="p-4">Kumulativní peněžní tok:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">{{ formatCurrency(cumulativeCashFlow(year)) }}</td>
        </tr>

        <!-- Analýza prodeje -->
        <tr class="text-sky-500 font-semibold">
          <td colspan="100%" class="p-4">Analýza prodeje</td>
        </tr>
        <tr class="border-t-2">
          <td class="p-4">Hodnota nemovitosti:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">{{ formatCurrency(propertyValueForYear(year)) }}</td>
        </tr>
        <tr>
          <td class="p-4">Zůstatek půjčky:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">-{{ formatCurrency(loanBalanceForYear(year)) }}</td>
        </tr>
        <tr>
          <td class="p-4">Náklady na prodej:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">-{{ formatCurrency(sellingCostsForYear(year)) }}</td>
        </tr>
        <tr class="border-t-2 border-gray-300 font-bold">
          <td class="p-4">Prodejní kapitál (Netto):</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">{{ formatCurrency(netSaleProceedsForYear(year)) }}</td>
        </tr>

        <!-- Návratnost investice -->
        <tr class="text-sky-500 font-semibold">
          <td colspan="100%" class="p-4">Návratnost investice</td>
        </tr>
        <tr class="border-t-2">
          <td class="p-4">ROI:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">{{ formatPercentage(roiForYear(year)) }}</td>
        </tr>
        <tr>
          <td class="p-4">IRR:</td>
          <td v-for="year in filteredYears" :key="year" class="p-4 text-right">{{ formatPercentage(irrForYear(year)) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { InvestmentService } from '@/functions/investmentService';

export default {
  name: 'CashFlowAnalysis',

  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      investmentYears: 30,
      selectedInterval: 'milestones',
    };
  },

  computed: {
    data() {
      return this.modelValue;
    },

    filteredYears() {
      const milestones = [1, 2, 3, 5, 10, 20, 30];
      let years = [];
      if (this.selectedInterval === 'milestones') {
        years = milestones.filter((year) => year <= this.investmentYears);
      } else {
        for (let i = 1; i <= this.investmentYears; i++) {
          if (i % this.selectedInterval === 0 || i === 1) {
            years.push(i);
          }
        }
      }
      return years;
    },
  },

  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('cs-CZ', {style: 'currency', currency: 'CZK'}).format(value);
    },

    formatPercentage(value) {
      return value !== null && value !== undefined ? value.toFixed(2) + '%' : '-';
    },

    // Výnosy
    grossRentForYear(year) {
      return InvestmentService.calculateGrossRentForYear(this.data, year);
    },

    vacancyLossForYear(year) {
      return InvestmentService.calculateVacancyLossForYear(this.data, year);
    },

    otherIncomeForYear(year, income) {
      return InvestmentService.calculateOtherIncomeForYear(this.data, year, income);
    },

    effectiveGrossIncomeForYear(year) {
      return InvestmentService.calculateEffectiveGrossIncomeForYear(this.data, year);
    },

    // Náklady
    expenseForYear(year, expense) {
      return InvestmentService.calculateExpenseForYear(this.data, year, expense);
    },

    totalOperatingExpensesForYear(year) {
      return InvestmentService.calculateOperatingExpensesForYear(this.data, year);
    },

    // NOI
    netOperatingIncomeForYear(year) {
      return InvestmentService.calculateNetOperatingIncomeForYear(this.data, year);
    },

    // Úvěr
    interestExpenseForYear(year) {
      return InvestmentService.calculateInterestExpenseForYear(this.data, year);
    },

    principalPaymentForYear(year) {
      return InvestmentService.calculatePrincipalPaymentForYear(this.data, year);
    },

    totalDebtServiceForYear(year) {
      return InvestmentService.calculateTotalDebtServiceForYear(this.data, year);
    },

    // Peněžní tok
    cashFlowBeforeTaxForYear(year) {
      return InvestmentService.calculateCashFlowForYear(this.data, year);
    },

    taxForYear(year) {
      return InvestmentService.calculateTaxForYear(this.data, year);
    },

    cashFlowAfterTaxForYear(year) {
      return InvestmentService.calculateCashFlowAfterTax(this.data, year);
    },

    cumulativeCashFlow(year) {
      let total = 0;
      for (let y = 1; y <= year; y++) {
        total += this.cashFlowAfterTaxForYear(y);
      }
      return total;
    },

    // Analýza prodeje
    propertyValueForYear(year) {
      return InvestmentService.calculatePropertyValueForYear(this.data, year);
    },

    loanBalanceForYear(year) {
      return InvestmentService.calculateLoanBalanceForYear(this.data, year);
    },

    sellingCostsForYear(year) {
      return InvestmentService.calculateSellingCostsForYear(this.data, year);
    },

    netSaleProceedsForYear(year) {
      return InvestmentService.calculateNetSaleProceedsForYear(this.data, year);
    },

    // Návratnost investice
    roiForYear(year) {
      return InvestmentService.calculateROI(this.data, year);
    },

    irrForYear(year) {
      return InvestmentService.calculatePropertyIRR(this.data, year);
    },
  },
};
</script>

<style scoped>
.table-container {
  width: 900px;
}
</style>
