<template>
  <div ref="dropdown" class="relative">
    <button @click="toggleDropdown" class="w-full p-4 border rounded-lg shadow-sm flex justify-between items-center">
      <span>{{ selectedProperty?.name || placeholder }}</span>
      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
      </svg>
    </button>
    <div v-if="dropdownOpen" class="absolute mt-2 w-full max-h-56 overflow-y-scroll bg-white border rounded-lg shadow-lg z-10">
      <div
          v-for="property in properties"
          :key="property.id"
          @click="selectProperty(property)"
          class="flex items-center p-4 hover:bg-gray-100 cursor-pointer"
      >
        <img v-if="property.image_url" :src="'https://rilo.b-cdn.net/' + property.image_url" alt="Property Image" class="w-12 h-12 rounded-full mr-4" />
        <img v-else src="https://rilo.b-cdn.net/static/blank-img.png"
             alt="Property Image"
             class="w-12 h-12 rounded-full mr-4"
        >
        <div>
          <p class="font-bold">{{ property.name }}</p>
          <p class="text-sm text-gray-500">{{ property.description }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectProperties",
  props: {
    properties: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Vyberte nemovitost",
    },
  },
  data() {
    return {
      dropdownOpen: false,
      selectedProperty: null,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    selectProperty(property) {
      this.selectedProperty = property;
      this.$emit("property-selected", property);
      this.dropdownOpen = false;
    },
    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.dropdownOpen = false;
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.z-10 {
  z-index: 10;
}
</style>
