<template>
  <div class="p-4 rounded-lg" v-if="user">
    <h2 class="text-2xl font-semibold mb-4">Správa předplatného</h2>
    <p class="text-gray-600 mb-6">
      Zde můžete spravovat své předplatné a sledovat jeho stav. Pokud je vaše předplatné aktivní, můžete ho spravovat. Pokud je předplatné neaktivní, máte možnost ho obnovit.
    </p>

    <!-- Předplatné je aktivní -->
    <div v-if="userActiveSub" class="p-6 bg-green-100 border border-green-400 rounded-lg shadow-lg">
      <h3 class="text-xl font-semibold mb-2 text-green-700">Předplatné je aktivní</h3>
      <p class="text-gray-600 mb-4">Vaše předplatné bylo vytvořeno <strong>{{ userActiveDateSub }}</strong>.</p>
      <button @click="manageSubscription" class="bg-green-600 text-white px-4 py-2 rounded-lg shadow hover:bg-green-500">
        Spravovat předplatné
      </button>
    </div>

    <!-- Předplatné není aktivní -->
    <div v-else class="p-6 bg-red-100 border border-red-400 rounded-lg shadow-lg">
      <h3 class="text-xl font-semibold mb-2 text-red-700">Předplatné není aktivní</h3>
      <p class="text-gray-600 mb-4">Momentálně nemáte aktivní předplatné. Můžete ho obnovit a znovu získat přístup ke všem funkcím.</p>
      <button @click="renewSubscription" class="bg-red-600 text-white px-4 py-2 rounded-lg shadow hover:bg-red-500">
        Obnovit předplatné
      </button>
    </div>
  </div>
</template>

<script>
import {useUserStore} from "@/pinia/userStore";
import moment from "moment";
export default {
  name: "SubscriptionSetting",

  computed:{
    user(){
      return useUserStore().user;
    },
    userActiveSub(){
      if(useUserStore().user){
        return useUserStore().user.active_subscription;
      }else{
        return false;
      }
    },
    userActiveDateSub(){
      if(this.user){
        return moment(this.user.subscription_created).format('DD.MM.YYYY');
      }else{
        return "";
      }
    }
  },

  methods: {
    async manageSubscription() {
        try {
          const response = await this.$axios.post('/create-customer-portal-session');
          window.location.href = response.data.portalUrl;
        } catch (error) {
          console.error('Error redirecting to customer portal:', error);
        }
    },
    async renewSubscription() {
      try {
        const response = await this.$axios.post('/create-checkout-session');

        window.location.href = response.data.checkoutUrl;
      } catch (error) {
        console.error('Chyba při vytváření předplatného:', error);
      }    },
  },
};
</script>
