<template>
  <div class="mx-auto p-6 pt-12 bg-gray-100 min-h-screen rounded-lg">
    <div class="flex flex-col sm:flex-row items-start sm:items-center w-full mb-6 gap-3">
      <h2 class="text-3xl font-extrabold text-gray-800 w-full">
        Plánované nemovitosti
      </h2>

      <div class="flex flex-col sm:flex-row w-full sm:w-auto gap-3">
        <button
            @click="openTagsModal"
            type="button"
            class="flex rounded-md items-center justify-center text-white bg-sky-500 hover:bg-sky-700 font-medium text-sm px-3 py-3 min-w-32"
        >
          <svg class="mr-2 w-4 h-4 invert" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.605 0h-10.604v10.609l13.39 13.391 10.609-10.605-13.395-13.395zm-7.019 6.414c-.781-.782-.781-2.047 0-2.828.782-.781 2.048-.781 2.828-.002.782.783.782 2.048 0 2.83-.781.781-2.046.781-2.828 0zm6.823 8.947l-4.243-4.242.708-.708 4.243 4.243-.708.707zm4.949.707l-7.07-7.071.707-.707 7.071 7.071-.708.707zm2.121-2.121l-7.071-7.071.707-.707 7.071 7.071-.707.707z"/></svg>
          Štítky
        </button>

        <router-link
            :to="{name: 'AddProperties'}"
            type="button"
            class="flex rounded-md items-center justify-center text-white bg-sky-700 hover:bg-sky-900 font-medium text-sm px-3 py-3 min-w-44"
        >
          <font-awesome-icon icon="plus" class="mr-2" />
          Přidat nemovitost
        </router-link>
      </div>
    </div>

    <div class="flex flex-col md:flex-row">
      <!-- Kategorií -->
      <div class="w-full md:w-1/5 mb-6 md:mb-0 md:mr-6">
        <div class="md:block flex overflow-x-auto scrollbar-hidden sticky top-14">
          <CategoryItem
              v-for="(item, index) in categories"
              :name="item.name"
              :image="item.image"
              :type="item.type"
              :disabled="item.disabled"
              @switch="setActive"
              :is-active="isActive(item.type)"
              :key="index"
              class="cursor-pointer transition-transform"
          />
        </div>
      </div>

      <div class="w-full md:w-4/5 bg-white p-6 rounded-lg shadow-lg mt-2">
        <!-- Vyhledávání a Seřazení -->
        <div class="flex justify-between flex-col items-center mb-6">
          <form @click.prevent="onSearch" class="w-full flex relative">
            <button @click.stop="toggleDropdown" id="dropdown-button" class="flex-shrink-0 z-10 inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-gray-200 border border-gray-300 rounded-l-lg hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-200 transition-all" type="button">
              Seřadit
              <svg class="w-2.5 h-2.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4 4-4"/>
              </svg>
            </button>
            <div v-if="isDropdownOpen" ref="dropdown" class="absolute top-9 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-54 mt-2">
              <ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdown-button">
                <li>
                  <button @click="setSortOption('priceDesc')" type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100">
                    <font-awesome-icon icon="money-bill-wave" class="mr-2" />
                    Cena od nejvyšší
                  </button>
                </li>
                <li>
                  <button @click="setSortOption('priceAsc')" type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100">
                    <font-awesome-icon icon="money-bill-wave" class="mr-2" />
                    Cena od nejnižší
                  </button>
                </li>
                <li>
                  <button @click="setSortOption('size')" type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100">
                    <font-awesome-icon icon="ruler-combined" class="mr-2" />
                    Užitná plocha (m²)
                  </button>
                </li>
                <li>
                  <button @click="setSortOption('maxROI')" type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100">
                    <font-awesome-icon icon="chart-line" class="mr-2" />
                    Nejvyšší ROI
                  </button>
                </li>
                <li>
                  <button @click="setSortOption('minROI')" type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100">
                    <font-awesome-icon icon="chart-line" class="mr-2" />
                    Nejnižší ROI
                  </button>
                </li>
                <li>
                  <button @click="setSortOption('maxYield')" type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100">
                    <font-awesome-icon icon="percentage" class="mr-2" />
                    Nejvyšší Yield
                  </button>
                </li>
                <li>
                  <button @click="setSortOption('minYield')" type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100">
                    <font-awesome-icon icon="percent" class="mr-2" />
                    Nejnižší Yield
                  </button>
                </li>
                <li>
                  <button @click="setSortOption('maxCashFlow')" type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100">
                    <font-awesome-icon icon="wallet" class="mr-2" />
                    Nejvyšší Cash Flow
                  </button>
                </li>
                <li>
                  <button @click="setSortOption('minCashFlow')" type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100">
                    <font-awesome-icon icon="wallet" class="mr-2" />
                    Nejnižší Cash Flow
                  </button>
                </li>
                <li>
                  <button @click="setSortOption('name')" type="button" class="inline-flex w-full px-4 py-2 hover:bg-gray-100">
                    <font-awesome-icon icon="sort-alpha-down" class="mr-2" />
                    Název (abeceda)
                  </button>
                </li>
              </ul>
            </div>
            <div class="relative w-full">
              <input
                  type="search"
                  id="search-dropdown"
                  @click.stop
                  v-model="searchQuery"
                  class="block p-2.5 w-full z-20 text-sm text-gray-900 bg-gray-100 rounded-r-lg border-l-gray-100 border-l-2 border border-gray-300 outline-0"
                  placeholder="Vyhledat nemovitost..."
                  required
              />
              <button type="submit" class="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-sky-800 rounded-r-lg border border-sky-800 hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-blue-300">
                <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
                <span class="sr-only">Search</span>
              </button>
            </div>
          </form>

          <div v-if="selectedTag" class="w-full flex justify-end mt-4">
            <button @click="clearTagFilter" class="text-red-500 flex items-center text-md">
              <font-awesome-icon icon="times" class="mr-1"/> Zrušit filtr
            </button>
          </div>
        </div>

        <!-- Hlavní obsah -->
        <div>
          <div v-if="properties.length > 0">
            <SingleProperty
                v-for="property in properties"
                :key="property.id"
                :property="property"
                :criteria="criteria"
                @delete="deleteProperty"
                @select-tag="selectTag"
            />
          </div>
          <div v-else-if="!isLoading" class="text-center text-gray-500 mt-8">
            <p>Přidejte svou první nemovitost!</p>
          </div>

          <div v-if="isLoading" class="flex justify-center my-4">
            <font-awesome-icon icon="spinner" spin size="2x" />
          </div>
        </div>
      </div>
    </div>
    <ModalApp
        :is-open="isTagsModalOpen"
        @close="closeTagsModal"
        title="Správce štítků"
        size="medium"
    >
      <TagsList />
    </ModalApp>
  </div>
</template>
<script>
import CategoryItem from "@/components/planned/CategoryItem.vue";
import SingleProperty from "@/components/planned/SingleProperty.vue";
import TagsList from "@/components/planned/TagsList.vue";
import ModalApp from "@/components/ModalApp.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { usePropertyStore } from '@/pinia/usePropertyStore';

export default {
  name: "PlannedProperties",

  emits: ['openSignModal'],

  data() {
    return {
      categories: [
        {
          name: 'K pronájmu',
          image: 'rent.png',
          type: 'rent',
        },
        {
          name: 'Flipování',
          image: 'flip.png',
          type: 'flip',
        },
        {
          name: 'Refinancování',
          image: 'invest.png',
          disabled: true,
          type: 'BRRR'
        },
        {
          name: 'Garáže',
          image: 'garage.svg',
          disabled: true,
          type: 'garage'
        }
      ],
      properties: [],
      page: 1,
      maxPage: null,
      limit: 10,
      isLoading: false,
      isTagsModalOpen: false,
      searchQuery: '',
      selectedTag: null,
      sortBy: 'created_at', // Výchozí řazení
      sortOrder: 'desc',
      isDropdownOpen: false,
    }
  },

  components: {
    FontAwesomeIcon,
    ModalApp,
    CategoryItem,
    SingleProperty,
    TagsList
  },

  watch: {
    sortBy() {
      this.resetProperties();
    },
    sortOrder() {
      this.resetProperties();
    },
    activeCategory() {
      this.resetProperties();
    },
    selectedTag() {
      this.resetProperties();
    }
  },

  computed: {
    activeCategory() {
      const propertyStore = usePropertyStore();
      return propertyStore.activeCategory;
    },

    criteria() {
      const propertyStore = usePropertyStore();
      return propertyStore.criteria;
    }
  },

  async mounted() {
    document.addEventListener('click', this.closeDropdown);
    window.addEventListener('scroll', this.onScroll);

    // Načtení počátečních dat
    const propertyStore = usePropertyStore();
    if (propertyStore.criteria === null) {
      await propertyStore.fetchCriteria(this.$axios);
    }

    this.resetProperties();
  },

  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdown);
    window.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    async fetchProperties() {
      if (this.isLoading || (this.maxPage && this.page > this.maxPage)) {
        return;
      }

      this.isLoading = true;

      try {
        const response = await this.$axios.get('/planned-properties', {
          params: {
            page: this.page,
            limit: this.limit,
            query: this.searchQuery,
            category: this.activeCategory,
            sort_by: this.sortBy,
            sort_order: this.sortOrder,
            tags: this.selectedTag ? this.selectedTag : [],
          },
        });

        const { properties, max_page } = response.data;

        this.properties.push(...properties);
        this.page += 1;
        this.maxPage = max_page;
      } catch (error) {
        console.error('Chyba při načítání nemovitostí:', error);
      } finally {
        this.isLoading = false;
      }
    },

    resetProperties() {
      this.properties = [];
      this.page = 1;
      this.maxPage = null;
      this.fetchProperties();
    },

    onScroll() {
      const bottomOfWindow = window.innerHeight + window.scrollY >= document.body.offsetHeight - 500;
      if (bottomOfWindow) {
        this.fetchProperties();
      }
    },

    setSortOption(option) {
      switch(option) {
        case 'priceDesc':
          this.sortBy = 'purchase_price';
          this.sortOrder = 'desc';
          break;
        case 'priceAsc':
          this.sortBy = 'purchase_price';
          this.sortOrder = 'asc';
          break;
        case 'size':
          this.sortBy = 'square_meters';
          this.sortOrder = 'desc';
          break;
        case 'maxROI':
          this.sortBy = 'ROI';
          this.sortOrder = 'desc';
          break;
        case 'minROI':
          this.sortBy = 'ROI';
          this.sortOrder = 'asc';
          break;
        case 'maxYield':
          this.sortBy = 'yield';
          this.sortOrder = 'desc';
          break;
        case 'minYield':
          this.sortBy = 'yield';
          this.sortOrder = 'asc';
          break;
        case 'maxCashFlow':
          this.sortBy = 'cashflow';
          this.sortOrder = 'desc';
          break;
        case 'minCashFlow':
          this.sortBy = 'cashflow';
          this.sortOrder = 'asc';
          break;
        case 'name':
          this.sortBy = 'name';
          this.sortOrder = 'asc';
          break;
        default:
          this.sortBy = 'created_at';
          this.sortOrder = 'desc';
      }
      this.isDropdownOpen = false;
    },

    onSearch() {
      this.resetProperties();
    },

    clearTagFilter() {
      this.selectedTag = null;
      this.resetProperties();
    },

    isActive(type) {
      const propertyStore = usePropertyStore();
      return propertyStore.activeCategory === type;
    },

    setActive(type) {
      const propertyStore = usePropertyStore();
      propertyStore.setActiveCategory(type);
      this.resetProperties();
    },

    selectTag(tagId) {
      this.selectedTag = tagId;
      this.resetProperties();
    },

    async deleteProperty(id) {
      try {
        await this.$axios.delete(`/planned-properties/${id}`);
        this.$flashMessage.success("Nemovitost byla úspěšně odstraněna");
        this.resetProperties();
      } catch (error) {
        console.error("Chyba při odstraňování nemovitosti:", error);
      }
    },

    closeTagsModal() {
      this.isTagsModalOpen = false;
    },

    openTagsModal() {
      this.isTagsModalOpen = true;
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    closeDropdown(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
        this.isDropdownOpen = false;
      }
    },
  }
}
</script>



<style scoped>
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Add some transition for hover effects */
.cursor-pointer {
  transition: transform 0.2s ease-in-out;
}

/* Example card shadow for property listing */
.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.shadow-sm {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
</style>
