<template>
  <div class="space-y-4">
    <h3 class="text-xl pl-2 font-semibold text-gray-800">
      <font-awesome-icon icon="person-shelter" size="xs"/>
      Dlouhodobé projekce
    </h3>

    <div class="bg-gray-100 px-4 py-8 rounded-lg space-y-8">
      <div v-if="!isFlipCategory">
        <label class="block text-gray-700 font-semibold">Očekávaná doba držení nemovitosti:</label>
        <div class="relative">
          <input
              v-model.number="form.estimated_investment_time"
              type="number"
              placeholder="Např. 30"
              class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
          <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">Roky</span>
        </div>
        <p class="text-sm text-gray-500">Kolik let chcete tuto nemovitost vlastnit a pronajímat?</p>
      </div>

      <!-- Tento blok bude zobrazen pouze při flip strategii -->
      <div v-else>
        <label class="block text-gray-700 font-semibold">Očekávaná doba držení nemovitosti (v měsících):</label>
        <div class="relative">
          <select
              v-model="form.estimated_investment_time"
          class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200"
          >
          <option v-for="month in 12" :key="month" :value="(month / 12).toFixed(6)">
            {{ month }} měsíc{{ month === 1 ? '' : 'e' }}
          </option>
          </select>

        </div>
        <p class="text-sm text-gray-500">Kolik měsíců plánujete nemovitost vlastnit?</p>
      </div>

      <div class="grid grid-cols-2 gap-8">
        <!-- Očekávaný roční růst hodnoty -->
        <div v-if="!isFlipCategory">
          <label class="block text-gray-700 font-semibold">Očekávaný roční růst hodnoty (%):</label>
          <div class="relative">
            <input
                v-model="form.property_appreciation"
                type="number"
                placeholder="Např. 3.5"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte očekávaný roční růst hodnoty nemovitosti.</p>
        </div>

        <!-- Předpokládaný roční růst nájemného -->
        <div v-if="!isFlipCategory">
          <label class="block text-gray-700 font-semibold">Předpokládaný roční růst nájemného (%):</label>
          <div class="relative">
            <input
                v-model="form.income_growth"
                type="number"
                placeholder="Např. 2"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte předpokládaný roční růst nájemného.</p>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-8">
        <!-- Předpokládaný roční nárůst nákladů -->
        <div v-if="!isFlipCategory">
          <label class="block text-gray-700 font-semibold">Předpokládaný roční nárůst nákladů (%):</label>
          <div class="relative">
            <input
                v-model="form.expense_growth"
                type="number"
                placeholder="Např. 1.5"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte předpokládaný roční nárůst nákladů.</p>
        </div>

        <!-- Prodejní náklady při prodeji -->
        <div v-if="!isFlipCategory">
          <label class="block text-gray-700 font-semibold">Prodejní náklady při prodeji (%):</label>
          <div class="relative">
            <input
                v-model="form.selling_costs"
                type="number"
                placeholder="Např. 6"
                class="mt-1 block w-full border border-gray-300 rounded-md p-2 pr-12 focus:ring focus:ring-blue-200">
            <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500">%</span>
          </div>
          <p class="text-sm text-gray-500">Zadejte prodejní náklady při prodeji nemovitosti.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LongTermProjections",
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    propertyCategory:{
      type: String,
      required: true,
      default: 'rent'
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },

    isFlipCategory(){
      return this.propertyCategory === 'flip';
    }
  }
}
</script>
